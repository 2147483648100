import React from "react";

export interface CustomersData {
  customer_name: string;
  email_address: string;
  mobile_number: string;
  country: string;
  transaction_count: string;
  action: React.ReactNode;
}

export const ColumnCustomers = [
  {
    Header: "Customer Name",
    accessor: "customer_name",
  },
  {
    Header: "Email Address",
    accessor: "email_address",
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_number",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Transaction Count",
    accessor: "transaction_count",
  },
  {
    Header: "",
    accessor: "action",
  },
];
