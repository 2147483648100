import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import Grid from "@material-ui/core/Grid";
import Card from "../../../components/Card/Card";
import Chart from "../../../components/Chart/Chart"
import ChartOutflow from "../../../components/Chart/ChartOutflow";
import BalanceCard from "../../../components/Card/BalanceCard";
import TimerIcon from "../../../assets/images/Icons/timer.svg";
import VolumeIcon from "../../../assets/images/Icons/volume-icon.svg";
import SettlementIcon from "../../../assets/images/Icons/settlement-icon.svg";
import BalanceIcon from "../../../assets/images/Icons/balance-card-icon.svg";
import TopMerchant from "./TopMerchant";
import MostTransaction from "./MostTransaction";
import LedgerIcon from "../../../assets/images/Icons/ledger-icon.svg";
import useApi from "../../../hooks/useApi";
import transactionsSummaryApi from "../../../api/transactionsSummary";
import topMerchantApi from "../../../api/topMerchant";
import inflowOutflowApi from "../../../api/inflow";
import client from "../../../api/client";

export interface ITopMerchant {
  logo: string;
  name: string;
  value: number;
  total_count: number;
}

const Dashboard = () => {
  const [filterParam, setFilterParam] = useState<any>("year");
  const [filterParamOutflow, setFilterParamOutflow] = useState<any>("year");
  const [inflowData, setInflowData] = useState<any>();
  const [outflowData, setOutflowData] = useState<any>();
  const getTransactionsSummaryApi = useApi(
    transactionsSummaryApi.getTransactionsSummary
  );

  useEffect(() => {
    getTransactionsSummaryApi.request();
  }, []);

  const getTopMerchantApi = useApi(topMerchantApi.getTopMerchant);
  // const getInflowOutflowApi = useApi(inflowOutflowApi.getInflowOutflow);

  const fetchInflow = async () => {
    const { data }: any = await client.get(
      `metric/inflow/outflow?type=${filterParam}`
    );
    setInflowData(data?.data);
  };

  const fetchOutflow = async () => {
    const { data }: any = await client.get(
      `metric/inflow/outflow?type=${filterParamOutflow}`
    );
    setOutflowData(data?.data);
  };

  useEffect(() => {
    fetchInflow();
  }, [filterParam]);

  useEffect(() => {
    fetchOutflow();
  }, [filterParamOutflow]);

  useEffect(() => {
    getTopMerchantApi.request();
    // getInflowOutflowApi.request();
    getTransactionsSummaryApi.request();
  }, []);

  const topMerchants = getTopMerchantApi?.data?.data;

  const data = getTransactionsSummaryApi?.data?.data;

  // const inflowOutflow = getInflowOutflowApi?.data?.data;

  const response = {
    count: data?.count,
    volume: data?.volume,
    settlements: data?.settlements,
    settlement_change: data?.last_week_settlements_percent_change,
    volume_change: data?.last_week_volume_percent_change,
    count_change: data?.last_week_count_percent_change,
  };

  return (
    <div className={styles.dashbordContainer}>
      {getTransactionsSummaryApi.loading || getTopMerchantApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Card
                icon={BalanceIcon}
                percentage={response.volume_change}
                amount={response.volume}
                transactionType="Total transaction balance"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                icon={TimerIcon}
                percentage={response.count_change}
                amount={response.count}
                transactionType="Total transaction count"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                icon={SettlementIcon}
                percentage={response.settlement_change}
                amount={response.settlements}
                transactionType="Total transaction settlements"
              />
            </Grid>
          </Grid>
          <div className={styles.balance}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <div className={styles.dashboardChart}>
                <Chart
                    inflowData={inflowData}
                    filterParam={filterParam}
                    setFilterParam={setFilterParam}
                  />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.dashboardChart}>
                <ChartOutflow
                    outflowData={outflowData}
                    filterParam={filterParamOutflow}
                    setFilterParam={setFilterParamOutflow}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={styles.balance}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <div className={styles.dashboardChart}>
                  <TopMerchant data={topMerchants} />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.dashboardChart}>
                  <MostTransaction data={topMerchants} />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
