import React from "react";
import styles from "./ComplianceDetails.module.scss";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";

const ComplianceDetailsDirectors = ({ directorsInfo }: any) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormLabel-root": {
        zIndex: "0"
      }
    }
  }));

  const classes = useStyles();

  const director = {
    name: directorsInfo?.name,
    valid_identification: directorsInfo?.valid_identification,
    identification: directorsInfo?.identification
  };

  console.log("director", director);
  return (
    <div className={styles.roles}>
      <Box>
        <div>
          <div>
            <TextField
              className={classes.root}
              label="Director's full name"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {director?.name === null || director?.name === ""
                      ? ""
                      : director?.name}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
            <TextField
              className={classes.root}
              label="Director's ID"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {director?.identification === null ||
                    director?.identification === ""
                      ? ""
                      : director?.identification}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
            <h5 className={styles.labelImage}>ID number</h5>
            {!!director?.valid_identification ? (
              <div className={styles.validId}>
                <img
                  src={director?.valid_identification}
                  width={50}
                  height={50}
                  alt="valid id"
                />
              </div>
            ) : (
              <div>No Valid Id yet</div>
            )}

            {/* <TextField
              className={classes.root}
              label="ID number"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {director?.valid_identification === null ||
                    director?.valid_identification === ""
                      ? ""
                      : director?.valid_identification}
                  </InputAdornment>
                )
              }}
              disabled={true}
            /> */}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ComplianceDetailsDirectors;
