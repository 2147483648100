export const menu = [
  {
    id: 1,
    menuName: "Home",
    url: "/dashboard"
  },
  {
    id: 2,
    menuName: "Transactions",
    url: "/dashboard/transactions"
  },
  {
    id: 3,
    menuName: "Accounts",
    url: "/dashboard/accounts"
  },
  {
    id: 5,
    menuName: "Limits",
    url: "/dashboard/limits"
  },
  {
    id: 4,
    menuName: "Settings",
    url: "/dashboard/settings"
  }
];
