import React, { useEffect, useState } from "react";
import styles from "./Roles.module.scss";
import rolesApi from "../../../api/roles";
import permissionsApi from "../../../api/permissions";
import useApi from "../../../hooks/useApi";
import { ReactComponent as RoleIcon } from "../../../assets/images/Icons/role.svg";
import { ReactComponent as MarkIcon } from "../../../assets/images/Icons/mark.svg";
import { ReactComponent as CustomRoleIcon } from "../../../assets/images/Icons/custom.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/Icons/edit.svg";
import CreateRole from "../../../components/ModalContent/Role/CreateRole";
import Footer from "../../../components/footer/Footer";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import client from "../../../api/client";
import EditRole from "../../../components/ModalContent/Role/EditRole";

const Roles = () => {
  const [roleDetails, setRoleDetails] = useState<any>();
  const [selectedMenu, setSelectedMenu] = useState<string | number>("");

  const dispatch = useDispatch();
  const getRolesApi = useApi(rolesApi.getRoles);
  const getPermissionsApi = useApi(permissionsApi.getPermissions);

  const fetchPermissions = async () => {
    await getPermissionsApi.request();
  };

  const fetchRoles = async () => {
    await getRolesApi.request();
  };

  const rolesData = getRolesApi?.data?.data;

  const permissionsData = getPermissionsApi?.data?.data;

  const viewRoleDetails = async (id: number) => {
    try {
      const data: any = await client.get(`role/details?roleid=${id}`);
      const message = data?.data?.message;
      setRoleDetails(data?.data?.data);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const [modalIsOpenCreate, setIsOpenCreate] = React.useState(false);

  function openModalCreate() {
    console.log("open modal");
    setIsOpenCreate(true);
  }

  function closeModalCreate() {
    setIsOpenCreate(false);
  }

  const handleCreateRole = () => {
    setIsOpenCreate(true);
  };

  useEffect(() => {
    fetchRoles();
    fetchPermissions();
  }, []);
  return (
    <div className={styles.rolesBody}>
      <Modal
        isOpen={modalIsOpenCreate}
        onRequestClose={closeModalCreate}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateRole
          closeModal={closeModalCreate}
          fetchPaymentLink={fetchRoles}
          permissions={permissionsData}
        />
      </Modal>
      <div className={styles.sidenav}>
        <div className={styles.sidenavHeader}>
          <span>Roles & Permissions</span>
        </div>
        <div className={styles.mt1}>
          <div onClick={openModalCreate} className={styles.rolesButton}>
            <CustomRoleIcon />
            <span className={styles.customRole}>Create custom role</span>
          </div>
        </div>
        <div className={styles.mt1}>
          {rolesData?.map((role: any, index: number) => (
            <div>
              <div
                className={
                  selectedMenu === role?.id
                    ? styles.activeSideItem
                    : styles.sideItem
                }
                onClick={() => {
                  setSelectedMenu(role?.id);
                  viewRoleDetails(role?.id);
                }}
              >
                {role?.name} ({role?.user_count})
              </div>
            </div>
          ))}
        </div>
        <div className={styles.mt1}></div>
      </div>
      {!!roleDetails && (
        <div className={styles.roles}>
          <div className={styles.rolesHeader}>{roleDetails?.name}</div>
          <div className={styles.rolesInfo}>{roleDetails?.description}</div>

          <div className={styles.mt2}>
            <div className={styles.rolesHeader}>Permissions</div>
            <div className={styles.permissionsList}>
              {!!roleDetails &&
                roleDetails?.permission?.map((permission: any) => (
                  <div className={styles.permissionsItems}>
                    <div className={styles.rolesLabel}>
                      {permission?.permission}
                    </div>
                    <div className={styles.mark}>
                      <MarkIcon />
                    </div>
                  </div>
                ))}
              <div className={styles.mt1}></div>
              <div
                onClick={() => setIsOpen(true)}
                className={styles.editButton}
              >
                <EditIcon />
                <span>Edit role</span>
              </div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
              >
                <EditRole
                  closeModal={closeModal}
                  fetchPaymentLink={fetchRoles}
                  permissions={roleDetails?.permission}
                  roleDetails={roleDetails}
                  allPermissions={permissionsData}
                />
              </Modal>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Roles;
