import React, { useMemo, useEffect, useState } from "react";
import styles from "./Customers.module.scss";
import FundIcon from "../../../assets/images/funded.svg";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  Column,
} from "react-table";
import { ReactComponent as RightArrow } from "../../assets/images/Icons/right-arrow.svg";
import { ReactComponent as LeftArrowFaded } from "../../assets/images/Icons/back-arrowfaded.svg";
// import { ReactComponent as EmptyTable } from "../../assets/images/empty-table.svg";

type DataColumn = {
  data: any;
  columns: any;
  emptyPlaceHolder: string;
  recent: boolean;
  value?: string;
  setPage?: any;
  pageIndexed?: any;
  // clickAction?: (id: string | number) => void;
};

const initialState = { hiddenColumns: ["id"] };

const PaginationTable = ({
  data,
  columns,
  emptyPlaceHolder,
  recent,
  value,
  setPage,
  pageIndexed,
}: // clickAction,
  Partial<DataColumn>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    prepareRow,
  } = useTable(
    { columns, data, initialState },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [searchVal, setSearchVal] = useState(globalFilter);

  const onChangeSearch = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 1000);

  useEffect(() => {
    setGlobalFilter(value);
    console.log("Value", value);
  }, [value]);

  return (
    <div className={styles.tableWrapper}>
      <table
        {...getTableProps()}
        style={{ width: "100%" }}
        className={styles.table}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={styles.tableHead}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ width: "100%" }}>
          {page?.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
              // className={
              //   clickAction ? styles.tableBodyHover : styles.tableBody
              // }
              // onClick={
              //   clickAction ? () => clickAction(row.values.id) : () => null
              // }
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "20px",
                        fontFamily: "'Kumbh Sans'",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        letterSpacing: "-0.011em",
                        color: "#424242",
                        borderBottom: "1px solid #e5e5e5",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className={styles.emptyState}>
          <div className={styles.noTransaction}>{emptyPlaceHolder}</div>
          {/* <EmptyTable /> */}
        </div>
      )}

      {!recent ? (
        <>
          {" "}
          {/* Pagination */}
          {data.length === 0 ? null : (
            <div className={styles.paginatedSection}>
              <div>
                <div className={styles.arrowDirections}>
                  <div
                    onClick={() => {
                      previousPage()
                      setPage(pageIndexed - 1)
                    }}
                    style={{ paddingRight: "10px" }}
                  >
                    <div>
                      {pageIndex + 1 === 1 ? (
                        <div style={{ cursor: "pointer" }}>
                          <LeftArrowFaded />
                          <span className={styles.previous}>Previous</span>
                        </div>
                      ) : (
                        <div style={{ cursor: "pointer" }}>
                          <RightArrow
                            style={{
                              transform: "rotate(180deg)",
                              cursor: "pointer",
                            }}
                          />
                          <span className={styles.previousTrue}>Previous</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <span className={styles.pageNumber}>
                    <span className={styles.pageIndex}>
                      {pageIndex + 1} of {pageCount}
                    </span>
                    {data.length === 0 ? null : (
                      <select
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                        }}
                        className={styles.selection}
                      >
                        {[5, 10, 20, 30, 40, 50].reverse().map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                          </option>
                        ))}
                      </select>
                    )}
                  </span>
                  {pageIndex + 1 === pageCount ? (
                    <div onClick={() => {
                      setPage(pageIndexed + 1)
                      nextPage()
                    }}>
                      <div style={{ cursor: "pointer" }}>
                        <span className={styles.nextFalse}>Next</span>
                        <LeftArrowFaded
                          style={{
                            transform: "rotate(180deg)",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div onClick={() => nextPage()}>
                      <div style={{ cursor: "pointer" }}>
                        <span className={styles.next}>Next</span>
                        <RightArrow />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Pagination */}
          {/* Show items */}
          {/* Show items */}{" "}
        </>
      ) : null}
    </div>
  );
};

export default PaginationTable;
