import React, { useState, Fragment, useEffect } from "react";
import styles from "./Compliance.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnCompliance,
  ComplianceData
} from "../../../types/TablesTypes/Compliance";
import { useHistory } from "react-router-dom";
import MenuOption from "../../../components/MenuOption/MenuOption";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import ApproveIcon from "../../../assets/images/Icons/approve.svg";
import RejectIcon from "../../../assets/images/Icons/reject.svg";
import DeactivateIcon from "../../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import subsidiariesApi from "../../../api/subsidiaries";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import settlementApi from "../../../api/settlementCycle";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import RejectCompliance from "./RejectCompliance/RejectCompliance";
import meApi from "../../../api/me";

const tableStatus = [
  { id: 1, name: "Active" },
  { id: 2, name: "New" },
  { id: 3, name: "Pending-Verification" },
  { id: 4, name: "In-Review" }
];

const Compliance = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState("");
  const [status, setStatus] = useState<string>("");
  const [tableRow, setTableRow] = useState<any[]>();
  const [merchantName, setMerchantName] = useState("");
  const [rejectId, setRejectId] = useState<any>();
  const [compliance, setCompliance] = useState<any>();

  const [page, setPage] = useState(1);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setRejectId(null);
    setMerchantName("");
    setIsOpen(false);
  }

  const [loading, setLoading] = useState(false);

  const fetchKYCRequest = async () => {
    setLoading(true);
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `subsidiary/all/paginated?status=${status}&limit=100&page=${page}`
      );
      setCompliance(data);
      dispatch(closeLoader());
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchKYCRequest();
  }, [status, page]);


  const getMeApi = useApi(meApi.getMe);

  useEffect(() => {
    getMeApi.request();
  }, []);


  const adminPermission = getMeApi?.data?.user?.permission;

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View compliance";
      },
      icon: ViewIcon,
      onclick: (
        emailAddress: string,
        businessName: string,
        e: any,
        status: string,
        id: number
      ) => {
        history.push(`/dashboard/compliance/compliance-details/${id}`);
      }
    },

    // {
    //   id: 2,
    //   name: (id: number) => {
    //     return adminPermission?.includes("Accept/decline KYC requests") && merchantStatus !== "Final-Review" && "Approve request";
    //   },

    //   icon: ApproveIcon,
    //   onclick: (
    //     emailAddress: string,
    //     businessName: string,
    //     e: any,
    //     status: string,
    //     subsidiaryId: number
    //   ) => {
    //     const payload = {
    //       subsidiary_id: subsidiaryId,
    //       reason: null,
    //       status: "Approved"
    //     };
    //     e.preventDefault();
    //     client
    //       .post("/kyc/subsidiary/verify", payload)
    //       .then((data: any) => {
    //         fetchKYCRequest();
    //         const message = data?.data?.message;
    //         dispatch(
    //           openToastAndSetContent({
    //             toastStyles: ToastSuccessStyles,
    //             toastContent: message
    //           })
    //         );
    //       })
    //       .catch((error: any) => {
    //         if (error.response) {
    //           const message = error?.response?.data?.message;
    //           dispatch(
    //             openToastAndSetContent({
    //               toastStyles: ToastErrorStyles,
    //               toastContent: message
    //             })
    //           );
    //         } else if (error.request) {
    //           console.log(error.request);
    //         } else {
    //           console.log("Error", error.message);
    //         }
    //       });
    //   }
    // },

    // {
    //   id: 3,
    //   name: (id: number) => {
    //     return "Reject request";
    //   },
    //   icon: RejectIcon,
    //   onclick: (
    //     emailAddress: string,
    //     businessName: string,
    //     e: any,
    //     status: string,
    //     subsidiaryId: number
    //   ) => {
    //     setRejectId(subsidiaryId);
    //     setMerchantName(businessName);
    //     setIsOpen(true);
    //   }
    // }
  ];

  const dataCompliance = () => {
    const tempArr: ComplianceData[] = [];
    compliance?.items
      ?.slice(0)
      .reverse()
      .forEach((complianceApi: any, index: number) => {
        return tempArr.push({
          id: complianceApi?.id,
          business_name: complianceApi?.business_name,
          email: complianceApi?.email,
          business_type: complianceApi?.business_type,
          country_name: complianceApi?.country,
          status: (
            <StatusView
              status={complianceApi?.status}
              green="Active"
              suspended="Suspended"
              final="Final-Review"
              red={"New"}
              orange={"Pending-Verification"}
              blue="In-Review"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          complianceApi?.email,
                          complianceApi?.business_name,
                          e,
                          complianceApi?.status,
                          complianceApi?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(complianceApi?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(complianceApi?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataCompliance());
  }, [compliance?.items]);

  const itemLength = compliance?.items?.length;

  return (
    <Fragment>
      <TableHeader
        pageName="KYC Requests & Compliance"
        data={compliance?.items}
        dataLength={compliance?.items?.length}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              {tableStatus.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => {
                    setStatus(name);
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        }
        value={value}
        setValue={setValue}
      />

      {loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnCompliance ? ColumnCompliance : []}
          value={value}
          pageIndexed={page}
          setPage={setPage}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <RejectCompliance
          rejectId={rejectId}
          merchantName={merchantName}
          fetchKYCRequest={fetchKYCRequest}
          closeModal={closeModal}
        />
      </Modal>
    </Fragment>
  );
};

export default Compliance;
