import React from "react";

export interface TransactionsData {
  date: string;
  customer_name: string;
  email: string;
  transaction_amount: string;
  payment_channel: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnTransactions = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Customer Name",
    accessor: "customer_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Transaction Amount",
    accessor: "transaction_amount",
  },
  {
    Header: "Payment Channel",
    accessor: "payment_channel",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "#",
    accessor: "action",
  },
];
