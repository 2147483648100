import React from "react";
import styles from "./RejectCompliance.module.scss";
import { ReactComponent as CloseIcon } from "../../../../assets/images/Icons/close.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/images/Icons/info.svg";
import OutlineTextInput from "../../../../components/input/OutlineTextInput";
import TextField from "@mui/material/TextField";
import client from "../../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../../redux/actions/toast/toastActions";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../../constants/toastStyles";

const RejectCompliance = ({
  closeModal,
  rejectId,
  merchantName,
  fetchKYCRequest,
  status
}: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    subsidiary_id: number;
    reason: string;
    status: string;
  }

  const initProps: initTypes = {
    subsidiary_id: rejectId,
    reason: "",
    status: "Rejected"
  };
  const [inputs, setInputs] = React.useState(initProps);

  const [message, setMessage] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const disableFunction = () => {
    if (merchantName && inputs.reason) {
      return true;
    }
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#016E20"
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#016E20",
      fontFamily: "'Kumbh Sans'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      maxWidth: 260,
      padding: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      letterSpacing: "-0.006em",
      color: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px"
    }
  }));

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const rejectCompliance = async () => {
    try {
      const data: any = await client.post(
        status === "Final-Review"
          ? "/kyc/subsidiary/final/verification"
          : "kyc/subsidiary/verify",
        inputs
      );

      const message = data?.data?.message;
      fetchKYCRequest();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    rejectCompliance();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Reject KYC request</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            inputName="name"
            inputLabel="Merchant name"
            inputValue={merchantName}
            inputType="text"
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="reason"
            inputLabel="Message"
            inputValue={inputs.reason}
            multiline
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        <div className={styles.deactivate} onClick={handleSubmit}>
          Send
        </div>
      </div>
    </div>
  );
};

export default RejectCompliance;
