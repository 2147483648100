import React, { Dispatch, useEffect, MouseEventHandler } from "react";
import styles from "./Settlementmenu.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";

type PaymentProps = {
  closeModal: MouseEventHandler<SVGSVGElement>;
  menuData: any;
};
const ChargebackMenuModal = ({ closeModal, menuData }: PaymentProps) => {
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Order details</div>
          <CloseIcon onClick={closeModal} />
        </div>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Date Created</div>
          <div className={styles.content}>
            {moment(menuData?.date_created).format("MMMM Do YYYY, h:mm a")}
          </div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Customer name</div>
          <div className={styles.content}>{menuData?.customer_name}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Customer email</div>
          <div className={styles.content}>{menuData?.customer_email}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Transaction amount</div>
          <div className={styles.content}>{menuData?.amount}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Payment Channel</div>
          <div className={styles.content}>{menuData?.payment_type}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Status</div>
          <div className={styles.content}>
            {menuData?.order_status === "Successful" ? (
              <div className={styles.completed}>Successful</div>
            ) : menuData?.order_status === "Initiated" ? (
              <div className={styles.processing}>Initiated</div>
            ) : (
              <div className={styles.declined}>Failed</div>
            )}
          </div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Merchant name</div>
          <div className={styles.content}>{menuData?.subsidiary_name}</div>
        </div>
        <div className={styles.modalDetails}>
          <div className={styles.link}>Reference</div>
          <div className={styles.content}>{menuData?.order_reference}</div>
        </div>
      </div>
    </div>
  );
};

export default ChargebackMenuModal;
