import React, { useState, Fragment, useEffect } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnCustomers,
  CustomersData
} from "../../types/TablesTypes/Customers";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import customersApi from "../../api/customers";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  closeLoader,
  openLoader
} from "../../redux/actions/loader/loaderActions";

const tableCountry = [
  { id: 1, name: "Nigeria" },
  { id: 2, name: "Kenya" },
  { id: 3, name: "Ghana" },
  { id: 3, name: "South Africa" }
];

const Customers = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [country, setCountry] = useState<string>("");
  const [customers, setCustomers] = useState<any>("");
  const getCustomersApi = useApi(customersApi.getCustomers);
  const dispatch = useDispatch();
  const history = useHistory();

  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  console.log("subsidiaryInfo", subsidiaryInfo?.data?.user?.id);

  const fetchCustomers = async () => {
    country.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(`customer/all/paginated`);
      setCustomers(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [country]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return id ? "View customer details" : null;
      },
      icon: ViewIcon,
      onclick: (
        customerEmail: string,
        e: React.SyntheticEvent,
        status: string,
        customerId: number
      ) => {
        history.push(`/dashboard/transactions/customer-details/${customerId}`);
      }
    }
  ];

  const dataCustomers = () => {
    const tempArr: CustomersData[] = [];
    customers.items
      ?.slice(0)
      .reverse()
      .forEach((customer: any, index: number) => {
        return tempArr.push({
          // date_created: moment(customers?.date_created).format(
          //   "MMMM Do YYYY, h:mm a"
          // ),
          customer_name: customer?.customer_name,
          email_address: customer?.email_address,
          mobile_number: customer?.mobile_number,
          country: customer?.country_name,
          transaction_count: customer?.transaction_count,
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          customer?.email_address,
                          e,
                          customer?.status,
                          customer?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(customer?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(customer?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataCustomers());
  }, [customers?.items]);

  const itemLength = customers?.items?.length;
  return (
    <Fragment>
      <TableHeader
        pageName="Customers"
        data={customers?.items}
        dataLength={customers?.items?.length}
        value={value}
        setValue={setValue}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Country</div>
            <div className={styles.filterBody}>
              {tableCountry.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => {
                    setCountry(name);
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        }
      />

      {getCustomersApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnCustomers ? ColumnCustomers : []}
          value={value}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
        />
      )}
    </Fragment>
  );
};

export default Customers;
