import React from "react";

export interface SubsidiariesData {
  business_name: string;
  email: string;
  business_type: string;
  country: string;
  users: number;
  subsidiary_group: string;
  action: React.ReactNode;
}

export const ColumnSubsidiaries = [
  {
    Header: "Business name",
    accessor: "business_name"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Business type",
    accessor: "business_type"
  },
  {
    Header: "Country",
    accessor: "country"
  },
  {
    Header: "# of users",
    accessor: "users"
  },
  {
    Header: "Subsidiary Group",
    accessor: "subsidiary_group"
  },
  {
    Header: "",
    accessor: "action"
  }
];
