import React, { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Business.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnSubsidiaries,
  SubsidiariesData,
} from "../../types/TablesTypes/Subsidiaries";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import subsidiariesApi from "../../api/subsidiaries";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";

const tableBusinessType = [
  { id: 1, name: "Individual" },
  { id: 2, name: "Company" },
];

const Subsidiaries = () => {
  const history = useHistory();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [businessType, setBusinessType] = useState<string>("");
  const getSubsidiariesApi = useApi(subsidiariesApi.getSubsidiaries);

  const [subsidiaries, setSubsidiaries] = useState<any>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();

  const fetchSubsidiaries = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `subsidiary/all/paginated?businesstype=${businessType}`
      );
      setSubsidiaries(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchSubsidiaries();
  }, [businessType]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View transactions";
      },
      icon: ViewIcon,
      onclick: (
        emailAddress: string,
        e: any,
        status: string,
        subsidiaryId: number
      ) => {
        history.push(
          `/dashboard/accounts/subsidiaries-details/${subsidiaryId}`
        );
      },
    },
  ];

  const dataSubsidiaries = () => {
    const tempArr: SubsidiariesData[] = [];
    subsidiaries?.items
      ?.slice(0)
      .reverse()
      .forEach((subsidiary: any, index: number) => {
        return tempArr.push({
          business_name: subsidiary?.business_name,
          email: subsidiary?.email,
          business_type: subsidiary?.business_type,
          country: subsidiary?.country,
          users: subsidiary?.user_count,
          subsidiary_group: subsidiary?.subsidiary_group,
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          subsidiary?.email_address,
                          e,
                          subsidiary?.status,
                          subsidiary?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(subsidiary?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(subsidiary?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataSubsidiaries());
  }, [subsidiaries?.items]);

  const itemLength = subsidiaries?.items?.length;

  return (
    <Fragment>
      <TableHeader
        pageName="Subsidiaries"
        data={subsidiaries?.items}
        dataLength={subsidiaries?.items?.length}
        value={value}
        setValue={setValue}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Business Type</div>
            <div className={styles.filterBody}>
              {tableBusinessType.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => {
                    setBusinessType(name);
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateCustomer
          fetchCustomers={fetchSubsidiaries}
          closeModal={closeModal}
        />
      </Modal>
      {getSubsidiariesApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnSubsidiaries ? ColumnSubsidiaries : []}
          value={value}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
        />
      )}
    </Fragment>
  );
};

export default Subsidiaries;
