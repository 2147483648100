import React, { useState, ReactNode, Fragment, useEffect } from "react";
import styles from "./Servicesmenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import PaymentModal from "../../ModalContent/PaymentModal/PaymentModal";
import OutlineTextInput from "../../input/OutlineTextInput";
import Select from "react-select";

const ServicesMenu = ({ serviceData, fetchServices }: any) => {
  const dispatch = useDispatch();
  const [referenceData, setReferenceData] = useState<any>();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const closeModalDetails = () => {
    setIsOpenDetails(false);
  };
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  const [providers, setProviders] = React.useState<any>([]);
  const [selectedProvider, setSelectedProvider] = React.useState<any>();

  const fetchProviders = async () => {
    try {
      const { data }: any = await client.get("providers/all");
      setProviders(data?.data);
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  let selectedProviderOptions = providers?.map(function (service: any) {
    return {
      value: service.id,
      label: service.name,
    };
  });

  interface initTypes {
    payment_option_name: string;
  }

  const initProps: initTypes = {
    payment_option_name: serviceData?.payment_option_name,
  };
  const [inputs, setInputs] = React.useState(initProps);

  // const disableFunction = () => {
  //   if (inputs.firstname && inputs.role) {
  //     return true;
  //   }
  // };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };
  const updatePaymentLink = async () => {
    try {
      const data: any = await client.patch(
        `services/providers/${serviceData?.id}/update`,
        {
          new_provider_id: selectedProvider?.value,
        }
      );
      const message = data?.data?.message;
      fetchServices();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    updatePaymentLink();
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName="Update Provider"
                onclick={() => setIsOpen(true)}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      // className={styles.modal}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Update provider</div>
              <CloseIcon onClick={closeModal} />
            </div>
          </div>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="payment_option_name"
                inputLabel="Payment Option"
                inputValue={inputs?.payment_option_name}
              />
              <div>
                <label className={styles.inputLabel}>Providers</label>
                <Select
                  defaultValue={selectedProvider}
                  onChange={setSelectedProvider}
                  options={selectedProviderOptions}
                  className={styles.select}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </div>
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            <div
              className={styles.deactivate}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Save
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenDetails}
        onRequestClose={closeModalDetails}
        contentLabel="Example Modal"
        style={customStyles}
      // className={styles.modal}
      >
        <PaymentModal
          closeModal={closeModalDetails}
          reference={referenceData?.data?.payment_link}
        />
      </Modal>
    </Fragment>
  );
};

export default ServicesMenu;
