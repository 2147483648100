import React, { useEffect } from "react";
import styles from "./Addcollection.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
    ToastErrorStyles,
    ToastSuccessStyles
} from "../../../constants/toastStyles";
import Select from "react-select";
import {
    openLoader,
    closeLoader
} from "../../../redux/actions/loader/loaderActions";

const AddPayoutLimit = ({ closeModal, fetchServices, role }: any) => {
    const dispatch = useDispatch();
    const [subsidiary, setSubsidiary] = React.useState<any>([]);
    const [selectedSubsidiary, setSelectedSubsidiary] = React.useState<any>();

    interface initTypes {
        currency_id: string;
        single_limit: string;
        frequency: string;
        cumm_daily_limit: string;
    }

    const initProps: initTypes = {
        currency_id: "1",
        single_limit: "",
        frequency: "",
        cumm_daily_limit: ""
    };
    const [inputs, setInputs] = React.useState(initProps);

    const disableFunction = () => {
        if (inputs.single_limit && inputs.frequency && inputs.cumm_daily_limit) {
            return true;
        }
    };

    let subOptions = subsidiary?.map(function (rol: any) {
        return { value: rol.id, label: rol.name };
    });


    const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const inputName = event.target.name;
        setInputs((prevState: initTypes) => {
            return {
                ...prevState,
                [inputName]: newValue
            };
        });
    };

    const createNewCustomer = async () => {
        dispatch(openLoader());
        try {
            const data: any = await client.post("limits/payout/add", {
                ...inputs,
                subsidiary_id: selectedSubsidiary?.value
            });

            const message = data?.data?.message;
            dispatch(closeLoader());
            fetchServices();
            closeModal();
            dispatch(
                openToastAndSetContent({
                    toastStyles: ToastSuccessStyles,
                    toastContent: message
                })
            );
        } catch (error: any) {
            dispatch(closeLoader());
            closeModal();
            const message = error?.response?.data?.message;
            dispatch(
                openToastAndSetContent({
                    toastStyles: ToastErrorStyles,
                    toastContent: message
                })
            );
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        createNewCustomer();
    };

    const getSubsidiary = async () => {
        dispatch(openLoader());
        try {
            const data: any = await client.get("subsidiary/all");
            setSubsidiary(data?.data?.data);
            dispatch(closeLoader());
        } catch (error: any) {
            dispatch(closeLoader());
            const message = error?.response?.data?.message;
            dispatch(
                openToastAndSetContent({
                    toastStyles: ToastErrorStyles,
                    toastContent: message
                })
            );
        }
    };

    useEffect(() => {
        getSubsidiary();
    }, []);
    return (
        <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
                <div className={styles.background}>
                    <div className={styles.linkDetails}>Add Payout Limit</div>
                    <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
                </div>
            </div>
            <div className={styles.businessForm}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <OutlineTextInput
                        handleChange={updateProps}
                        inputName="single_limit"
                        inputLabel="Single Limit"
                        inputValue={inputs.single_limit}
                        inputType="number"
                    />
                    <OutlineTextInput
                        handleChange={updateProps}
                        inputName="frequency"
                        inputLabel="Frequency"
                        inputValue={inputs.frequency}
                        inputType="number"
                    />
                    <OutlineTextInput
                        handleChange={updateProps}
                        inputName="cumm_daily_limit"
                        inputLabel="Cumulative Daily Limit"
                        inputValue={inputs.cumm_daily_limit}
                    />
                    <div>
                        <label className={styles.inputLabel}>Subsidiary</label>
                        <Select
                            defaultValue={selectedSubsidiary}
                            onChange={setSelectedSubsidiary}
                            options={subOptions}
                            // isMulti
                            // styles={customStyles}
                            className={styles.select}
                        />
                    </div>
                </form>
            </div>
            <div className={styles.modalFooter}>
                <div className={styles.cancel} onClick={closeModal}>
                    Cancel
                </div>
                {disableFunction() ? (
                    <div className={styles.deactivate} onClick={handleSubmit}>
                        Add Limit
                    </div>
                ) : (
                    <div className={styles.notClickable}>Add Limit</div>
                )}
            </div>
        </div>
    );
};

export default AddPayoutLimit;
