import React, { useState, useEffect } from "react";
import styles from "./Createbusiness.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactComponent as InfoIcon } from "../../../assets/images/Icons/info.svg";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import countriesApi from "../../../api/countries";
import useApi from "../../../hooks/useApi";
import Select from "react-select";

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const isNumbers = (str: string) => /^[0-9]*$/.test(str);
const isEmail = (str: string) =>
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    str
  );
const CreateBusiness = ({ closeModal, fetchBusinesses }: any) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [value, setValue] = React.useState("");

  const [touchedFirstName, setTouchedFirstName] = React.useState(false);
  const [touchedNumber, setTouchNumber] = React.useState(false);
  const [touchedEmail, setEmail] = React.useState(false);
  const [touchedLastName, setTouchedLastName] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");

  const getCountriesApi = useApi(countriesApi.getCountries);

  const fetchCountries = async () => {
    getCountriesApi.request();
  };

  const [selectedCountry, setSelectedCountry] = React.useState<any>();

  let selectedCountryOptions = getCountriesApi?.data?.data?.map(function (
    selectedCountry: any
  ) {
    return {
      value: selectedCountry.short_name,
      label: selectedCountry.name,
      currency: selectedCountry.country_code,
    };
  });

  const selectedCountryShort = selectedCountry?.value;

  interface initTypes {
    name: string;
    business_type: string;
    business_email: any;
    mobile: number | undefined;
    country: string;
    mcc_category: string;
    first_name: string;
    last_name: string;
    user_email: string;
    password: string;
  }

  const initProps: initTypes = {
    name: "",
    business_type: "",
    business_email: "",
    mobile: undefined,
    mcc_category: "",
    country: "",
    first_name: "",
    last_name: "",
    user_email: "",
    password: "",
  };

  const [inputs, setInputs] = React.useState(initProps);

  const payload = {
    name: inputs?.name,
    business_type: inputs?.business_type,
    business_email: inputs?.business_email,
    mobile: `${selectedCountry?.currency}${inputs?.mobile}`,
    mcc_category: inputs?.mcc_category,
    country: selectedCountryShort,
    user_details: {
      first_name: inputs?.first_name,
      last_name: inputs?.last_name,
      user_email: inputs?.user_email,
      password: inputs?.password,
    },
  };

  const disableFunction = () => {
    if (
      inputs.name &&
      inputs.business_type &&
      inputs.business_email &&
      inputs.mobile &&
      selectedCountryShort &&
      inputs.first_name &&
      inputs.last_name &&
      inputs.user_email &&
      inputs.password
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const createNewCustomer = async () => {
    try {
      const data: any = await client.post("business/create", payload);

      const message = data?.data?.message;
      fetchBusinesses();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleBusinessName = () => {
    setTouchedLastName(false);
    setTouchNumber(false);
    setEmail(false);
    setTouchedFirstName(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createNewCustomer();
  };
  useEffect(() => {
    fetchCountries();
  }, []);
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Add new business</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Business Name"
            inputValue={inputs?.name}
            inputType="text"
            onFocus={handleBusinessName}
            InputHelper={touchedFirstName && errorText}
            error={touchedFirstName && errorText}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="business_email"
            inputLabel="Business email"
            inputValue={inputs.business_email}
            inputType="email"
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="mobile"
            inputLabel="Mobile number"
            inputType="number"
            inputValue={inputs?.mobile}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="business_type"
            inputLabel="Business Type"
            inputSelect
            inputOption={["Individual", "Company"]}
            inputValue={inputs.business_type}
            inputType="text"
          />

          <div>
            <label className={styles.inputLabel}>Country</label>
            <Select
              defaultValue={selectedCountry}
              onChange={setSelectedCountry}
              options={selectedCountryOptions}
              className={styles.select}
              menuPortalTarget={document.body}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          </div>

          {/* <OutlineTextInput
            handleChange={updateProps}
            inputName="country"
            inputLabel="Country"
            inputSelect
            inputOption={["NG", "GH"]}
            inputValue={inputs.country}
            inputType="text"
          /> */}

          <OutlineTextInput
            handleChange={updateProps}
            inputName="mcc_category"
            inputLabel="MCC category"
            inputType="number"
            inputValue={inputs.mcc_category}
          />

          <div className={styles.businessType}>User Information</div>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="first_name"
            inputLabel="First name"
            inputValue={inputs.first_name}
            inputType="text"
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="last_name"
            inputLabel="Last name"
            inputValue={inputs.last_name}
            inputType="text"
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="user_email"
            inputLabel="User email"
            inputType="email"
            inputValue={inputs.user_email}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="password"
            inputLabel="Password"
            inputType="password"
            inputValue={inputs.password}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create
          </div>
        ) : (
          <div className={styles.notClickable}>Create </div>
        )}
      </div>
    </div>
  );
};

export default CreateBusiness;
