import React, { useState, useEffect, Fragment } from "react";
import styles from "./Customerdetails.module.scss";
import { useParams } from "react-router-dom";
import client from "../../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import {
  ColumnTransactions,
  TransactionsData,
} from "../../../types/TablesTypes/Transactions";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import transactionApi from "../../../api/transactions";
import useApi from "../../../hooks/useApi";
import ChargebackMenu from "../../../components/MenuOption/SettlementMenu/ChargebackMenu";
import StatusView from "../../../components/StatusView/StatusView";
import { dataTransaction } from "../../../utils/subMenu";
import TableHeader from "../../../components/TableHeader/TableHeader";
import PaginationTable from "../../../components/table/pagination-table";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";

const tableStatus = [
  { id: 1, name: "Initiated" },
  { id: 2, name: "Pending-Verification" },
  { id: 3, name: "Successful" },
];

const CustomerDetails = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [transactionDetails, setTransactionDetails] = useState<any>([]);
  const [value, setValue] = useState("");
  const [status, setStatus] = useState<string>("");
  const getTransactionApi = useApi(transactionApi.getTransactions);
  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`/customer/all/paginated/?id=${id}`)
      .then((res: any) => {
        setCustomerDetails(res?.data?.items[0]);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`/order/all/paginated/?customerid=${id}`)
      .then((res: any) => {
        setTransactionDetails(res?.data?.items);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  const dataTransactionDetails = () => {
    const tempArr: any[] = [];
    transactionDetails.items
      ?.slice(0)
      .reverse()
      .forEach((transactions: any, index: number) => {
        return tempArr.push({
          date: moment(transactions?.date_created).format(
            "MMMM Do YYYY, h:mm a"
          ),
          customer_name: transactions?.customer_name,
          email: transactions?.customer_email,
          transaction_amount: `${transactions?.currency} ${numberWithCommas(
            transactions?.amount
          )}`,
          payment_channel: transactions.payment_type,
          status: (
            <StatusView
              status={transactions?.order_status}
              green="Successful"
              red="Failed"
              orange="Initiated"
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransactionDetails());
  }, [transactionDetails]);

  return (
    <div>
      {isLoading ? (
        <div />
      ) : (
        <div className={styles.topTable}>
          <div className={styles.detailsHeader}>Customer Details</div>
          <div className={styles.tableContent}>
            <div className={styles.customerInfo}>
              <div className={styles.detailsValue}>Customer Name</div>
              <div className={styles.detailsKey}>
                {customerDetails?.customer_name}
              </div>
            </div>
            <div className={styles.customerInfo}>
              <div className={styles.detailsValue}>Customer email</div>
              <div className={styles.detailsKey}>
                {customerDetails?.email_address}
              </div>
            </div>
            <div className={styles.customerInfo}>
              <div className={styles.detailsValue}>Mobile phone</div>
              <div className={styles.detailsKey}>
                {customerDetails?.mobile_number}
              </div>
            </div>
            <div className={styles.customerInfo}>
              <div className={styles.detailsValue}>Country</div>
              <div className={styles.detailsKey}>
                {customerDetails?.country_name}
              </div>
            </div>
          </div>
        </div>
      )}

      <>
        <TableHeader
          pageName="Transactions"
          data={transactionDetails?.items}
          dataLength={transactionDetails?.items?.length}
          value={value}
          setValue={setValue}
          FilterComponent={
            <div>
              <div className={styles.filterSubHeading}>Status</div>
              <div className={styles.filterBody}>
                {tableStatus.map(({ id, name }) => (
                  <div
                    key={id}
                    onClick={() => {
                      setStatus(name);
                    }}
                  >
                    {name}
                  </div>
                ))}
              </div>
            </div>
          }
        />

        {transactionDetails.loading ? (
          <div className={styles.loading} />
        ) : (
          <PaginationTable
            data={tableRow ? tableRow : []}
            columns={ColumnTransactions ? ColumnTransactions : []}
            value={value}
            emptyPlaceHolder={"You currently do not have any customers"}
          />
        )}
      </>
    </div>
  );
};

export default CustomerDetails;
