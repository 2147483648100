import React, { useState, Fragment, useEffect } from "react";
import styles from "./Business.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnSettlements,
  SettlementsData,
} from "../../types/TablesTypes/Settlements";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import settlementsApi from "../../api/settlements";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { numberWithCommas } from "../../utils/formatNumber";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import PaymentModal from "../../components/ModalContent/PaymentModal/PaymentModal";
import PaymentLinkMenu from "../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import SettlementMenu from "../../components/MenuOption/SettlementMenu/SettlementMenu";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";

const tableCurrency = [
  { id: 1, name: "KES" },
  { id: 2, name: "NGN" },
  { id: 3, name: "USD" },
  { id: 4, name: "GHS" },
  { id: 5, name: "ZAR" },
];

const Settlements = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [currency, setCurrency] = useState<string>("");
  const [settlements, setSettlements] = useState<any>();
  const getSettlementsApi = useApi(settlementsApi.getSettlements);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();

  const fetchSettlements = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(`settlement/all?currency=${currency}`);
      setSettlements(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchSettlements();
  }, [currency]);

  const menuOptions = [
    {
      id: 1,
      name: (status: string) => {
        return status === "Active"
          ? "Blacklist Customer"
          : "Whitelist Customer";
      },
      icon: DeactivateIcon,
      onclick: (
        customerEmail: string,
        e: React.SyntheticEvent,
        status: string
      ) => {
        const payload = {
          customer_email: customerEmail,
          action: status === "Active" ? "blacklist" : "whitelist",
          reason: "test",
        };
        e.preventDefault();
        client
          .post("/subsidiary/customer/blacklist-whitelist", payload)
          .then((data: any) => {
            fetchSettlements();
            const message = data?.data?.message;
            dispatch(
              openToastAndSetContent({
                toastStyles: ToastSuccessStyles,
                toastContent: message,
              })
            );
          })
          .catch((error: any) => {
            if (error.response) {
              const message = error?.response?.data?.message;
              dispatch(
                openToastAndSetContent({
                  toastStyles: ToastErrorStyles,
                  toastContent: message,
                })
              );
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      },
    },
    {
      id: 2,
      name: (id: number) => {
        return id ? "View details" : null;
      },
      icon: ViewIcon,
      onclick: () => {},
    },
  ];

  const dataSettlements = () => {
    const tempArr: SettlementsData[] = [];
    settlements?.items
      ?.slice(0)
      .reverse()
      .forEach((settlement: any, index: number) => {
        return tempArr.push({
          business_name: settlement?.subsidiary_name,
          settlement_amount: `${settlement?.currency} ${numberWithCommas(
            settlement?.transaction_amount
          )}`,
          settlement_fee: `${settlement?.currency} ${numberWithCommas(
            settlement?.fee
          )}`,
          currency: settlement?.currency,
          settlement_cycle: settlement?.settlement_cycle,
          status: (
            <StatusView
              status={settlement.settlement_status}
              green="Completed"
              red={`${
                settlement.settlement_status === null ? "Failed" : "N/A"
              }`}
              orange="Pending"
            />
          ),
          action: (
            <SettlementMenu
              subsidiaryId={settlement?.id}
              menuData={settlement}
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataSettlements());
  }, [settlements?.items]);

  const itemLength = settlements?.items?.length;

  return (
    <Fragment>
      <TableHeader
        pageName="Settlements"
        data={settlements?.items}
        dataLength={settlements?.items?.length}
        value={value}
        setValue={setValue}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Currencies</div>
            <div className={styles.filterBody}>
              {tableCurrency.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => {
                    setCurrency(name);
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateCustomer
          fetchCustomers={fetchSettlements}
          closeModal={closeModal}
        />
      </Modal>
      {getSettlementsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnSettlements ? ColumnSettlements : []}
          value={value}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
        />
      )}
    </Fragment>
  );
};

export default Settlements;
