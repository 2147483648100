import React from "react";
import styles from "./Createcustomer.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import Select from "react-select";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";

const CreateCustomer = ({ closeModal, fetchCustomers, role }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    first_name: string;
    email: string;
    last_name: string;
  }

  const initProps: initTypes = {
    first_name: "",
    email: "",
    last_name: ""
  };
  const [inputs, setInputs] = React.useState(initProps);

  const [selectedRole, setSelectedRole] = React.useState<any>();

  let roleOptions = role?.map(function (rol: any) {
    return { value: rol.id, label: rol.name };
  });

  const disableFunction = () => {
    if (inputs.first_name && inputs.email && inputs.last_name && selectedRole) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const createNewCustomer = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post("user/create", {
        ...inputs,
        role: selectedRole?.label
      });

      const message = data?.data?.message;
      dispatch(closeLoader());
      fetchCustomers();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      closeModal();

      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createNewCustomer();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New user</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="first_name"
            inputLabel="First name"
            inputValue={inputs.first_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="last_name"
            inputLabel="Last name"
            inputValue={inputs.last_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="email"
            inputLabel="Email address"
            inputValue={inputs.email}
          />

          <div>
            <label className={styles.inputLabel}>Role</label>
            <Select
              defaultValue={selectedRole}
              onChange={setSelectedRole}
              options={roleOptions}
              // isMulti
              // styles={customStyles}
              className={styles.select}
            />
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new user
          </div>
        ) : (
          <div className={styles.notClickable}>Create new user</div>
        )}
      </div>
    </div>
  );
};

export default CreateCustomer;
