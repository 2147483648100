import React, { useEffect, useState } from "react";
import styles from "./ComplianceDetails.module.scss";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import StatusView from "../../../components/StatusView/StatusView";

const ComplianceDetailsProfile = ({ profileDetails }: any) => {
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormLabel-root": {
        zIndex: "0"
      }
    }
  }));

  const classes = useStyles();

  const profile = {
    business_type: profileDetails?.business_type,
    email: profileDetails?.email,
    country: profileDetails?.country,
    description: profileDetails?.description,
    legal_business_name: profileDetails?.legal_business_name,
    bvn: profileDetails?.bvn,
    industry: profileDetails?.industry,
    address: profileDetails?.address,
    twitter: profileDetails?.twitter,
    facebook: profileDetails?.facebook,
    instagram: profileDetails?.instagram,
    city: profileDetails?.city,
    website: profileDetails?.website,
    bvn_verified: profileDetails?.bvn_verified,
  };

  return (
    <div className={styles.roles}>
      <Box>
        <div>
          <div className={styles.rolesHeader}>Profile</div>
          <div className={styles.rolesInfo}></div>

          <div className={styles.mt2}>
            <div>
              <TextField
                className={classes.root}
                label="Business type"
                id="outlined-start-adornment"
                sx={{ m: 1, width: "90%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {!!profileDetails &&
                        (profile?.business_type === null ||
                          profile?.business_type === "")
                        ? ""
                        : profile?.business_type}
                    </InputAdornment>
                  )
                }}
                disabled={true}
              />
              <TextField
                className={classes.root}
                label="Business email"
                id="outlined-start-adornment"
                sx={{ m: 1, width: "90%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {profile?.email === null || profile?.email === ""
                        ? ""
                        : profile?.email}
                    </InputAdornment>
                  )
                }}
                disabled={true}
              />
              <TextField
                className={classes.root}
                label="Country"
                id="outlined-start-adornment"
                sx={{ m: 1, width: "90%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {profile?.country === null || profile?.country === ""
                        ? ""
                        : profile?.country}
                    </InputAdornment>
                  )
                }}
                disabled={true}
              />
              <TextField
                className={classes.root}
                label="Address"
                id="outlined-start-adornment"
                sx={{ m: 1, width: "90%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {profile?.address === null || profile?.address === ""
                        ? ""
                        : profile?.address}
                    </InputAdornment>
                  )
                }}
                disabled={true}
              />
              <TextField
                className={classes.root}
                label="Website"
                id="outlined-start-adornment"
                sx={{ m: 1, width: "90%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {profile?.website === null || profile?.website === ""
                        ? "N/a"
                        : profile?.website}
                    </InputAdornment>
                  )
                }}
                disabled={true}
              />
              {
                profile?.business_type === "Individual" && (
                  <>
                    <TextField
                      className={classes.root}
                      label="Twitter"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: "90%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {profile?.twitter === null || profile?.twitter === ""
                              ? "N/a"
                              : profile?.twitter}
                          </InputAdornment>
                        )
                      }}
                      disabled={true}
                    />

                    <TextField
                      className={classes.root}
                      label="Instagram"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: "90%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {profile?.instagram === null || profile?.instagram === ""
                              ? "N/a"
                              : profile?.instagram}
                          </InputAdornment>
                        )
                      }}
                      disabled={true}
                    />
                    <TextField
                      className={classes.root}
                      label="Facebook"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: "90%" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {profile?.facebook === null || profile?.facebook === ""
                              ? "N/a"
                              : profile?.facebook}
                          </InputAdornment>
                        )
                      }}
                      disabled={true}
                    />
                  </>
                )
              }
              <TextField
                className={classes.root}
                label="Description"
                id="outlined-start-adornment"
                multiline
                rows={3}
                sx={{ m: 1, width: "90%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {profile?.description === null ||
                        profile?.description === ""
                        ? ""
                        : profile?.description}
                    </InputAdornment>
                  )
                }}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className={styles.mt2}></div>
        <div className={styles.rolesHeader}>Registration</div>
        <div className={styles.mt2}>
          <div>
            <TextField
              className={classes.root}
              label="Legal business name"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {profile?.legal_business_name === null ||
                      profile?.legal_business_name === ""
                      ? ""
                      : profile?.legal_business_name}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "5px"
            }}>BVN <StatusView status={profile?.bvn_verified ? "Approved" : "Pending-approval"} orange="Pending-approval" green="Approved" style={{
              marginLeft: "8px"
            }} /></div>
            <TextField
              className={classes.root}
              label="BVN"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {profile?.bvn === null || profile?.bvn === ""
                      ? ""
                      : profile?.bvn}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
            <TextField
              className={classes.root}
              label="Industry"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {profile?.industry === null || profile?.industry === ""
                      ? ""
                      : profile?.industry}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ComplianceDetailsProfile;
