import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,

  headers: {
    "content-type": "application/json",
     CORS_ALLOW_ALL_ORIGINS: true,
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Headers": " Content-Type",
     "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE"
  }
});

client.interceptors.request.use(
  async (config) => {
    const access_token = sessionStorage.getItem("access_token");
    config.headers.authorization = `Bearer ${access_token}`;
    return config;
  },
  (error) => {
    console.log("errorssss", error?.response?.data?.messag?.data);
    if (error?.response?.data?.message.includes("login")) {
      sessionStorage.clear();
      window.location.href = "/";
    } else return Promise.reject(error);
  }
);

// client.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     if (error.response.status === 400) {
//       sessionStorage.clear();
//       Promise.reject(error);
//       return (window.location.href = "/");
//     }
//     return Promise.reject(error);
//   }
// );

export const formClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,

  headers: {
    "content-type": "multipart/form-data"
  }
});

formClient.interceptors.request.use(
  async (config) => {
    const access_token = sessionStorage.getItem("access_token");
    config.headers.authorization = `Bearer ${access_token}`;
    return config;
  },
  (error) => {
    if (error?.response?.data?.message.includes("login")) {
      window.location.href = "/";
    } else return Promise.reject(error);
  }
);

export default client;
