import React from "react";

export interface ServicesData {
  payment_option: string;
  payment_option_name: string;
  provider: string;
  date_created: string;
  date_updated: string;
  action: React.ReactNode;
}

export const ColumnServices = [
  {
    Header: "Payment Option",
    accessor: "payment_option",
  },
  {
    Header: "Payment Option Name",
    accessor: "payment_option_name",
  },
  {
    Header: "Provider",
    accessor: "provider",
  },
  {
    Header: "Date Created",
    accessor: "date_created",
  },
  {
    Header: "Date Updated",
    accessor: "date_updated",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
