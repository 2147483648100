import React, { useState } from "react";
import { ChangeEvent } from "react";
import styles from "./Profile.module.scss";
import ImagePlaceholder from "../../../assets/images/Icons/image-placeholder.png";
import { ReactComponent as UploadIcon } from "../../../assets/images/Icons/upload-image.svg";
import { ReactComponent as SaveIcon } from "../../../assets/images/Icons/save.svg";
import { ReactComponent as ResetIcon } from "../../../assets/images/Icons/reset-password.svg";
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import { useSelector, useDispatch } from "react-redux";
import client, { formClient } from "../../../api/client";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";
import { truncate } from "fs";

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const isNumbers = (str: string) => /^[0-9]*$/.test(str);
const isEmail = (str: string) =>
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    str
  );
const Profile = () => {
  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const { avatar, first_name, email_address, last_name, mobile_number } =
    subsidiaryInfo?.data?.user;

  interface initTypes {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    password: string;
    new_password: string;
    avatarImage: any;
  }

  const dispatch = useDispatch();
  const initProps: initTypes = {
    firstname: first_name,
    lastname: last_name,
    email: email_address,
    phone: mobile_number,
    password: "",
    new_password: "",
    avatarImage: ""
  };

  const [inputs, setInputs] = React.useState(initProps);
  const [errorText, setErrorText] = React.useState("");
  const [touchedFirstName, setTouchedFirstName] = React.useState(false);
  const [touchedLastName, setTouchedLastName] = React.useState(false);
  const [touchedNumber, setTouchNumber] = React.useState(false);
  const [touchedEmail, setEmail] = React.useState(false);

  const [file, setFile] = useState("");

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isAlphabets(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    } else {
      setErrorText("Please input alphabets only");
    }
  };

  const updatePropsPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const updateEmailProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isEmail(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    } else {
      setErrorText("Please input alphabets only");
    }
  };

  const updatePropsNumbers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isNumbers(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    } else {
      setErrorText("Please input numbers only");
    }
  };

  const updateImageProps = (event: any) => {
    const newValue = event?.target?.files[0];
    const inputName = event?.target?.name;
    setInputs((prevState: any) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  let formData = new FormData();

  // const disableFunction = () => {
  //   if (inputs.firstname && inputs.lastname && inputs.email && inputs.phone) {
  //     return true;
  //   } else if (
  //     inputs.firstname.length < 3 ||
  //     inputs.lastname.length < 3 == false
  //   ) {
  //     dispatch(
  //       openToastAndSetContent({
  //         toastStyles: ToastErrorStyles,
  //         toastContent: "Please input at least 3 alphabets",
  //       })
  //     );
  //   }
  //   {
  //     dispatch(
  //       openToastAndSetContent({
  //         toastStyles: ToastErrorStyles,
  //         toastContent: "Please input only alphabets",
  //       })
  //     );
  //   }
  // };

  formData.append("first_name", inputs.firstname); //append the values with key, value pair
  formData.append("last_name", inputs.lastname);
  formData.append("email_address", inputs.email);
  formData.append("mobile_number", inputs.phone);
  formData.append("avatar", inputs?.avatarImage);

  const handleSave = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("user/update", formData)
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles
          })
        );
      });
  };

  const changePassword = (e: React.SyntheticEvent) => {
    dispatch(openLoader());
    client
      .post(`user/password/change`, {
        password: inputs.password,
        new_password: inputs.new_password
      })
      .then((res: any) => {
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const handleFirstName = () => {
    setTouchedLastName(false);
    setTouchNumber(false);
    setEmail(false);
    setTouchedFirstName(true);
  };

  const handleLastName = () => {
    setTouchedLastName(true);
    setTouchNumber(false);
    setEmail(false);
    setTouchedFirstName(false);
  };

  const handleEmail = () => {
    setTouchedLastName(false);
    setTouchNumber(false);
    setEmail(true);
    setTouchedFirstName(false);
  };

  const handlePhoneNumber = () => {
    setTouchedLastName(false);
    setTouchNumber(true);
    setEmail(false);
    setTouchedFirstName(false);
  };

  console.log("avatar", inputs?.avatarImage);
  return (
    <div className={styles.profile}>
      <div className={styles.profileCard}>
        <div className={styles.logoText}>Avatar</div>
        <div className={styles.imagePlaceholder}>
          <img
            src={
              inputs?.avatarImage
                ? URL.createObjectURL(inputs?.avatarImage)
                : ImagePlaceholder
            }
            alt="logo-placeholder"
            width={100}
            height={100}
            style={{ borderRadius: "8px" }}
          />
        </div>
        <div className={styles.uploadButton}>
          <UploadIcon />
          {/* <span>Upload Image</span> */}
          {/* <OutlineTextInput
            inputName="avatar"
            inputLabel="Director's ID"
            handleChange={updateImageProps}
            inputType="file"
            inputShrink
            // style={{ display: "none" }}
          ></OutlineTextInput> */}
          <input
            type="file"
            name="avatarImage"
            // value={inputs?.avatar}
            onChange={updateImageProps}
          />
        </div>
      </div>
      <div className={styles.profileCard} style={{ marginTop: "30px" }}>
        <div className={styles.logoText}>Business information</div>
        <div className={styles.profileForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="firstname"
              inputLabel="First Name"
              inputValue={inputs.firstname}
              onFocus={handleFirstName}
              InputHelper={touchedFirstName && errorText}
              error={touchedFirstName && errorText}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="lastname"
              inputLabel="Last Name"
              inputValue={inputs.lastname}
              onFocus={handleLastName}
              InputHelper={touchedLastName && errorText}
              error={touchedLastName && errorText}
            />
            <OutlineTextInput
              handleChange={updateEmailProps}
              inputName="email"
              inputLabel="Email address"
              inputValue={inputs.email}
              inputType="email"
              onFocus={handleEmail}
              InputHelper={touchedEmail && errorText}
              error={touchedEmail && errorText}
            />
            <OutlineTextInput
              handleChange={updatePropsNumbers}
              inputName="phone"
              inputLabel="Phone number"
              inputValue={inputs.phone}
              onFocus={handlePhoneNumber}
              InputHelper={touchedNumber && errorText}
              error={touchedNumber && errorText}
            />
          </form>
          <div className={styles.uploadButton} onClick={handleSave}>
            <div className={styles.deactivate}>
              <SaveIcon /> <span>Save changes</span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.profileCard} style={{ marginTop: "30px" }}>
        <div className={styles.logoText}>Password</div>
        <div className={styles.subText}>Update your password</div>
        <div className={styles.profileForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updatePropsPassword}
              inputName="password"
              inputLabel="Password"
              inputType="password"
              inputValue={inputs.password}
            />

            <OutlineTextInput
              handleChange={updatePropsPassword}
              inputName="new_password"
              inputLabel="New password"
              inputType="password"
              inputValue={inputs.new_password}
            />
          </form>
          <div
            className={styles.uploadButton}
            onClick={(e: any) => {
              changePassword(e);
            }}
          >
            <ResetIcon /> <span>Reset password</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
