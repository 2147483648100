import React from "react";
import styles from "./Signin.module.scss";
import Logo from "../../assets/images/Logos/Pay-Invert-Logo.svg";
import { ReactComponent as ResetPasswordIcon } from "../../assets/images/Icons/reset-password.svg";
import { ReactComponent as AccountIcon } from "../../assets/images/Icons/account.svg";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../constants/toastStyles";
import { useParams } from "react-router-dom";
import queryString from "query-string";

const VerifyUser = () => {
  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams<{ token: string; email: string }>();

  const parsed = queryString.parse(window.location.search);

  interface initTypes {
    email?: string;
    otp?: string;
    password?: string;
  }

  const initProps: initTypes = {
    email: `${token}`,
    otp: "",
    password: ""
  };

  const [inputs, setInputs] = React.useState(initProps);
  const [value, setValue] = React.useState("");

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const handleReset = async () => {
    try {
      const data: any = await client.post("user/verify/account", {
        token: token,
        password: inputs.password
      });
      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
      history.push("/signin");
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleReset();
  };

  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} width={207} height={56} alt="pay-invert" />
      </div>
      <div className={styles.signinForm}>
        <div className={styles.signIn}>
          Verify <span className={styles.emailText}>{parsed?.email}</span>
        </div>
        <div className={styles.businessForm}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="password"
              inputLabel="Password*"
              inputType="password"
              inputValue={inputs?.password}
              notRequired={true}
              InputRef={{
                required: true
              }}
            />

            <div onClick={handleSubmit} className={styles.signInButton}>
              <ResetPasswordIcon />
              <span>Reset Password</span>
            </div>
          </form>
        </div>
      </div>

      <div className={styles.sidebarFooter}>
        <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
        <div>All rights reserved</div>
      </div>
    </div>
  );
};

export default VerifyUser;
