import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import styles from "./DashboardRoutes.module.scss";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import Dashboard from "../views/Home/Dashboard/Dashboard";
import Compliance from "../views/Home/Compliance/Compliance";
import ComplianceDetail from "../views/Home/Compliance/ComplianceDetail";
import Users from "../views/Settings/User/Users";
import Roles from "../views/Settings/Roles/Roles";
import SettlementCycle from "../views/Settings/Settlement/SettlementCycle";
import SettlementGroups from "../views/Settings/Settlement/SettlementGroups";
import SettlementGroupsDetails from "../views/Settings/Settlement/SettlementGroupsDetails";
import Notifications from "../views/Settings/Notifications/Notifications";
import Profile from "../views/Settings/Profile/Profile";
import AllTransactions from "../views/Transactions/AllTransactions";
import Customers from "../views/Transactions/Customers";
import Chargebacks from "../views/Transactions/Chargebacks";
import CustomerDetails from "../views/Transactions/CustomerDetails/CustomerDetails";
import Business from "../views/Accounts/Business";
import BusinessDetails from "../views/Accounts/BusinessDetails";
import Subsidiaries from "../views/Accounts/Subsidiaries";
import SubsidiariesDetails from "../views/Accounts/SubsidiariesDetails";
import Settlements from "../views/Accounts/Settlements";
import SettlementDetails from "../views/Accounts/SettlementDetails";
import TransactionDetails from "../views/Transactions/TransactionDetails/TransactionDetails";
import Services from "../views/Settings/Services/Services";
import Orders from "../views/Transactions/Orders";
import Limit from "../views/Limits/CollectionLimit/CollectionLimit";
import CollectionLimit from "../views/Limits/CollectionLimit/CollectionLimit";
import PendingLimit from '../views/Limits/PendingLimit/PendingLimits';
import PayoutLimit from '../views/Limits/PayoutLimit/PayoutLimit';

export default function DashboardRoutes() {

  const ScrollToTop = () => {
    const { pathname } = useLocation();


    console.log("pathname", pathname);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <Router>
      <ScrollToTop />
      <div className={styles.routerContainer}>
        <NavBar />
        <div className={styles.switchContainer}>
          <Switch>
            <Route exact path="/dashboard/">
              <Dashboard />
            </Route>
            <Route exact path="/dashboard/compliance" component={Compliance} />
            <Route
              exact
              path="/dashboard/compliance/compliance-details/:id"
              component={ComplianceDetail}
            ></Route>
            <Route exact path="/dashboard/settings" component={Users} />
            <Route exact path="/dashboard/settings/roles" component={Roles} />
            <Route
              exact
              path="/dashboard/settings/settlement-cycle"
              component={SettlementCycle}
            />
            <Route
              exact
              path="/dashboard/settings/settlement-groups"
              component={SettlementGroups}
            />
            <Route
              exact
              path="/dashboard/settings/settlement-groups/:id"
              component={SettlementGroupsDetails}
            />
            <Route
              exact
              path="/dashboard/settings/notifications"
              component={Notifications}
            />
            <Route
              exact
              path="/dashboard/settings/profile"
              component={Profile}
            />
            <Route
              exact
              path="/dashboard/settings/services"
              component={Services}
            />
            <Route
              exact
              path="/dashboard/limits"
              component={CollectionLimit}
            />
            <Route
              exact
              path="/dashboard/limits/create"
              component={PendingLimit}
            />
            <Route
              exact
              path="/dashboard/limits/payout"
              component={PayoutLimit}
            />
            <Route
              exact
              path="/dashboard/transactions"
              component={AllTransactions}
            />
            <Route
              exact
              path="/dashboard/transactions/transaction-details/:id"
              component={TransactionDetails}
            ></Route>
            <Route
              exact
              path="/dashboard/transactions/customers"
              component={Customers}
            />
            <Route
              exact
              path="/dashboard/transactions/customer-details/:id"
              component={CustomerDetails}
            ></Route>
            <Route
              exact
              path="/dashboard/transactions/orders"
              component={Orders}
            />
            <Route
              exact
              path="/dashboard/transactions/chargebacks"
              component={Chargebacks}
            />
            <Route exact path="/dashboard/accounts" component={Business} />
            <Route
              exact
              path="/dashboard/accounts/business-details/:id"
              component={BusinessDetails}
            />
            <Route
              exact
              path="/dashboard/accounts/subsidiaries"
              component={Subsidiaries}
            />
            <Route
              exact
              path="/dashboard/accounts/subsidiaries-details/:id"
              component={SubsidiariesDetails}
            ></Route>
            <Route
              exact
              path="/dashboard/accounts/settlements"
              component={Settlements}
            />
            <Route
              exact
              path="/dashboard/accounts/settlement-details/:id"
              component={SettlementDetails}
            ></Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}
