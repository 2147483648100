import React, { Fragment, useState, useEffect } from "react";
import styles from "./Business.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card";
import Chart from "../../components/Chart/ChartInflowOutflow";
import TimerIcon from "../../assets/images/Icons/timer.svg";
import TableHeader from "../../components/TableHeader/TableHeader";
import SettlementIcon from "../../assets/images/Icons/settlement-icon.svg";
import BalanceIcon from "../../assets/images/Icons/balance-card-icon.svg";
import ChargebacksIcon from "../../assets/images/Icons/chargeback.svg";
import VirtualWalletIcon from "../../assets/images/Icons/virtual-wallets.svg";
import CardIcon from "../../assets/images/Icons/card-icon.svg";
import InfoIcon from "../../assets/images/Icons/info-icon.svg";
import client from "../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import {
  ColumnTransactions,
  TransactionsData,
} from "../../types/TablesTypes/Transactions";
import {
  SubsidiariesData,
  ColumnSubsidiaries,
} from "../../types/TablesTypes/Subsidiaries";
import {
  CustomersData,
  ColumnCustomers,
} from "../../types/TablesTypes/Customers";
import StatusView from "../../components/StatusView/StatusView";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import useApi from "../../hooks/useApi";
import transactionsApi from "../../api/transactions";
import subsidiariesApi from "../../api/subsidiaries";
import customersApi from "../../api/customers";
import moment from "moment";
import { useParams } from "react-router-dom";
import inflowOutflowApi from "../../api/inflow";
import { numberWithCommas } from "../../utils/formatNumber";

const tableStatus = [
  { id: 1, name: "Initiated" },
  { id: 2, name: "Pending-Verification" },
  { id: 3, name: "Successful" },
];

const tableBusinessType = [
  { id: 1, name: "Individual" },
  { id: 2, name: "Company" },
];

const tableCountry = [
  { id: 1, name: "Nigeria" },
  { id: 2, name: "Kenya" },
  { id: 3, name: "Ghana" },
  { id: 3, name: "South Africa" },
];

const BusinessDetails = () => {
  const [value, setValue] = useState("");
  const [transactionValue, setTransactionValue] = useState("");
  const [subsidiaryValue, setSubsidiaryValue] = useState("");
  const [customerValue, setCustomerValue] = useState("");
  const [status, setStatus] = useState<string>("");
  const [businessType, setBusinessType] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [subsidiariesDetails, setSubsidiariesDetails] = useState<any>([]);
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const [transactionsDetails, setTransactionDetails] = useState<any>([]);
  const [transactionSummaryDetail, settransactionSummaryDetail] = useState<any>(
    []
  );
  const [balancesChargebacks, setbalancesChargebacks] = useState<any>([]);
  const [tableRow, setTableRow] = useState<any[]>();
  const [tableSubsidiaries, setTableSubsidiaries] = useState<any[]>();
  const [tableCustomers, setTableCustomers] = useState<any[]>();
  const gettransactionsApi = useApi(transactionsApi.getTransactions);
  const getSubsidiariesApi = useApi(subsidiariesApi.getSubsidiaries);
  const getCustomersApi = useApi(customersApi.getCustomers);

  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [filterParam, setFilterParam] = useState<any>("year");
  const [inflowOutflowData, setInflowOutflowData] = useState<any>();

  const fetchInflow = async () => {
    const { data }: any = await client.get(
      `metric/inflow/outflow?type=${filterParam}`
    );
    setInflowOutflowData(data?.data);
  };

  useEffect(() => {
    fetchInflow();
  }, [filterParam]);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`metric/transaction/summary?businessid=${id}`)
      .then((res: any) => {
        settransactionSummaryDetail(res?.data?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`metric/balances/chargebacks?businessid=${id}`)
      .then((res: any) => {
        setbalancesChargebacks(res?.data?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  const count = transactionSummaryDetail?.count;
  const volume = transactionSummaryDetail?.volume;
  const settlements = transactionSummaryDetail?.settlements;
  const balance = balancesChargebacks?.available_balance;
  const ledger_balance = balancesChargebacks?.ledger_balance;
  const chargebacks = balancesChargebacks.chargebacks;
  const balance_change =
    balancesChargebacks.last_week_available_balance_percent_change;
  const ledger_balance_change =
    balancesChargebacks.last_week_ledger_balance_percent_change;
  const chargebacks_change =
    balancesChargebacks.last_week_chargebacks_percent_change;
  const settlement_change =
    transactionSummaryDetail?.last_week_settlements_percent_change;
  const volume_change =
    transactionSummaryDetail?.last_week_volume_percent_change;
  const count_change = transactionSummaryDetail?.last_week_count_percent_change;

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`order/all/paginated?customerid=${id}&status=${status}`)
      .then((res: any) => {
        // console.log("paginated", res?.data);
        setTransactionDetails(res?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, [status]);

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`order/all/paginated?subsidiaryid=${id}`)
      .then((res: any) => {
        // console.log("paginated", res?.data);
        setTransactionDetails(res?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`subsidiary/all/paginated?id=${id}`)
      .then((res: any) => {
        // console.log("paginated", res?.data);
        setSubsidiariesDetails(res?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`customer/all/paginated?id=${id}`)
      .then((res: any) => {
        // console.log("paginated", res?.data);
        setCustomerDetails(res?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View business details";
      },
      // icon: ViewIcon,
      onclick: (
        emailAddress: string,
        e: any,
        status: string,
        customerId: number
      ) => {
        // history.push(
        //   `/dashboard/transactions/transaction-details/${customerId}`
        // );
      },
    },
    {
      id: 2,
      name: (status: string) => {
        return status === "Active"
          ? "Blacklist Customer"
          : "Whitelist Customer";
      },
      // icon: DeactivateIcon,
      onclick: (
        customerEmail: string,
        e: React.SyntheticEvent,
        status: string
      ) => {
        const payload = {
          customer_email: customerEmail,
          action: status === "Active" ? "blacklist" : "whitelist",
          reason: "test",
        };
        e.preventDefault();
        client
          .post("/subsidiary/customer/blacklist-whitelist", payload)
          .then((data: any) => {
            fetchtransactions();
            const message = data?.data?.message;
            dispatch(
              openToastAndSetContent({
                toastStyles: ToastSuccessStyles,
                toastContent: message,
              })
            );
          })
          .catch((error: any) => {
            if (error.response) {
              const message = error?.response?.data?.message;
              dispatch(
                openToastAndSetContent({
                  toastStyles: ToastErrorStyles,
                  toastContent: message,
                })
              );
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      },
    },
  ];

  const fetchtransactions = async () => {
    dataTransactions();
  };

  useEffect(() => {
    fetchtransactions();
  }, []);

  const dataTransactions = () => {
    const tempArr: any[] = [];
    transactionsDetails?.items
      ?.slice(0)
      .reverse()
      .forEach((transactions: any, index: number) => {
        return tempArr.push({
          date: moment(transactions?.date_created).format(
            "MMMM Do YYYY, h:mm a"
          ),
          customer_name: transactions?.customer_name,
          email: transactions?.customer_email,
          transaction_amount: `${transactions?.currency} ${numberWithCommas(
            transactions?.amount
          )}`,
          payment_channel: transactions?.payment_type,
          status: (
            <StatusView
              status={transactions.order_status}
              green="Successful"
              red="Pending-Verification"
              orange="Initiated"
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransactions);
  }, [transactionsDetails?.items]);

  const fetchSubsidiaries = async () => {
    dataSubsidiaries();
  };

  useEffect(() => {
    fetchSubsidiaries();
  }, []);

  const dataSubsidiaries = () => {
    const tempArr: any[] = [];
    subsidiariesDetails?.items
      ?.slice(0)
      .reverse()
      .forEach((subsidiaries: any, index: number) => {
        return tempArr.push({
          business_name: subsidiaries?.business_name,
          email: subsidiaries?.email,
          business_type: subsidiaries?.business_type,
          country: subsidiaries?.country,
          users: subsidiaries?.user_count,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableSubsidiaries(dataSubsidiaries);
  }, [subsidiariesDetails?.items]);

  const fetchCustomers = async () => {
    dataCustomers();
  };

  const dataCustomers = () => {
    const tempArr: any[] = [];
    customerDetails?.items
      ?.slice(0)
      .reverse()
      .forEach((customers: any, index: number) => {
        return tempArr.push({
          customer_name: customers?.customer_name,
          email_address: customers?.email_address,
          mobile_number: customers?.mobile_number,
          country: customers?.country_name,
          transaction_count: customers?.transaction_count,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableCustomers(dataCustomers);
  }, [customerDetails?.items]);

  return (
    <div>
      {isLoading ? (
        <div />
      ) : (
        <div className={styles.dashbordContainer}>
          <div className={styles.mb}>
            <span className={styles.detailsHeader}>Businesses</span>{" "}
            <span className={styles.detailsSubHeader}>
              {" "}
              &gt; Business details
            </span>
          </div>
          {transactionSummaryDetail.loading ? (
            <div className={styles.loading} />
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Card
                    icon={TimerIcon}
                    percentage={count_change}
                    amount={count}
                    transactionType="Total transaction count"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card
                    icon={CardIcon}
                    percentage={volume_change}
                    amount={volume}
                    transactionType="Total transaction balance"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Card
                    icon={SettlementIcon}
                    percentage={settlement_change}
                    amount={settlements}
                    transactionType="Total transaction settlements"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Grid item>
                    <div className={styles.mt0} />
                    <Card
                      icon={BalanceIcon}
                      percentage={balance_change}
                      amount={balance}
                      transactionType="Available balance"
                      // info_icon={InfoIcon}
                    />
                  </Grid>
                  <Grid item>
                    <div className={styles.mt1}>
                      <Card
                        icon={CardIcon}
                        percentage={ledger_balance_change}
                        amount={ledger_balance}
                        transactionType="Ledger balance"
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={styles.mt1}>
                      <Card
                        icon={ChargebacksIcon}
                        percentage={chargebacks_change}
                        amount={chargebacks}
                        transactionType="Chargebacks"
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item md={8} xs={12}>
                  <div className={styles.dashboardChart}>
                    <Chart
                      inflowOutflow={inflowOutflowData}
                      filterParam={filterParam}
                      setFilterParam={setFilterParam}
                    />
                  </div>
                </Grid>
              </Grid>
              <div className={styles.mt1}>
                <Fragment>
                  <TableHeader
                    pageName="Transactions"
                    data={transactionsDetails?.items}
                    dataLength={transactionsDetails?.items?.length}
                    value={transactionValue}
                    setValue={setTransactionValue}
                    FilterComponent={
                      <div>
                        <div className={styles.filterSubHeading}>Status</div>
                        <div className={styles.filterBody}>
                          {tableStatus.map(({ id, name }) => (
                            <div
                              key={id}
                              onClick={() => {
                                setStatus(name);
                              }}
                            >
                              {name}
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  />
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                  >
                    <CreateCustomer
                      fetchtransactions={fetchtransactions}
                      closeModal={closeModal}
                    />
                  </Modal>
                  {gettransactionsApi.loading ? (
                    <div className={styles.loading} />
                  ) : (
                    <PaginationTable
                      data={tableRow ? tableRow : []}
                      columns={ColumnTransactions ? ColumnTransactions : []}
                      emptyPlaceHolder="You currently do not have any transactions"
                      value={transactionValue}
                    />
                  )}
                </Fragment>
              </div>

              <div className={styles.mt1}>
                <Fragment>
                  <TableHeader
                    pageName="Subsidiaries"
                    data={subsidiariesDetails?.items}
                    dataLength={subsidiariesDetails?.items?.length}
                    value={subsidiaryValue}
                    setValue={setSubsidiaryValue}
                    FilterComponent={
                      <div>
                        <div className={styles.filterSubHeading}>
                          Business Type
                        </div>
                        <div className={styles.filterBody}>
                          {tableBusinessType.map(({ id, name }) => (
                            <div
                              key={id}
                              onClick={() => {
                                setBusinessType(name);
                              }}
                            >
                              {name}
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  />
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                  ></Modal>
                  {getSubsidiariesApi.loading ? (
                    <div className={styles.loading} />
                  ) : (
                    <PaginationTable
                      data={tableSubsidiaries ? tableSubsidiaries : []}
                      columns={ColumnSubsidiaries ? ColumnSubsidiaries : []}
                      emptyPlaceHolder="You currently do not have any subsidiaries"
                      value={subsidiaryValue}
                    />
                  )}
                </Fragment>
              </div>

              <div className={styles.mt1}>
                <Fragment>
                  <TableHeader
                    pageName="Customers"
                    data={customerDetails?.items}
                    dataLength={customerDetails?.items?.length}
                    value={customerValue}
                    setValue={setCustomerValue}
                    FilterComponent={
                      <div>
                        <div className={styles.filterSubHeading}>Country</div>
                        <div className={styles.filterBody}>
                          {tableCountry.map(({ id, name }) => (
                            <div
                              key={id}
                              onClick={() => {
                                setCountry(name);
                              }}
                            >
                              {name}
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                  />
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                  ></Modal>
                  {getCustomersApi.loading ? (
                    <div className={styles.loading} />
                  ) : (
                    <PaginationTable
                      data={tableCustomers ? tableCustomers : []}
                      columns={ColumnCustomers ? ColumnCustomers : []}
                      emptyPlaceHolder="You currently do not have any customers"
                      value={customerValue}
                    />
                  )}
                </Fragment>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BusinessDetails;
