import React, { useState, Fragment, useEffect } from "react";
import PaginationTable from "../../components/table/pagination-table";
import { useParams } from "react-router-dom";
import settlementsApi from "../../api/settlements";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import styles from "./Business.module.scss";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import {
  ColumnSettlements,
  SettlementsData,
} from "../../types/TablesTypes/Settlements";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";

const SettlementDetails = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [settlementDetails, setsettlementDetails] = useState<any>([]);
  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return id ? "Delete group" : null;
      },
      icon: ViewIcon,
      onclick: () => {},
    },
  ];

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`/settlement/all?subsidiaryid=${id}`)
      .then((res: any) => {
        setsettlementDetails(res?.data?.items);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  // const fetchSettlementDetail = async () => {
  //     settlementDetails
  //   };
  //   useEffect(() => {
  //     fetchSettlementDetail();
  //   }, []);

  const dataSettlements = () => {
    const tempArr: SettlementsData[] = [];
    settlementDetails
      ?.slice(0)
      .reverse()
      .forEach((settlements: any, index: number) => {
        return tempArr.push({
          business_name: settlements?.subsidiary_name,
          settlement_amount: settlements?.transaction_amount,
          settlement_fee: settlements?.fee,
          currency: settlements?.currency,
          settlement_cycle: settlements?.settlement_cycle,
          status: (
            <StatusView
              status={settlements.settlement_status}
              green="Completed"
              red={`${
                settlements.settlement_status === null || "" ? "Failed" : "N/A"
              }`}
              orange="Pending"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          settlements?.email_address,
                          e,
                          settlements?.status
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(settlements?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(settlements?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataSettlements());
  }, [settlementDetails]);
  return (
    <Fragment>
      <TableHeader
        pageName="Settlement Groups"
        data={settlementDetails}
        dataLength={settlementDetails.length}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <LinkIcon className={styles.link} />
            Create new customer
          </div>
        }
      />
      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnSettlements ? ColumnSettlements : []}
        emptyPlaceHolder="There currently do not have any settlements"
      />
    </Fragment>
  );
};

export default SettlementDetails;
