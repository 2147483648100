import React from "react";

export interface BusinessData {
  business_name: string;
  email_address: string;
  business_type: string;
  country_name: string;
  subsidiaries: string;
  status: string |  React.ReactNode;
  action: React.ReactNode;
}

export const ColumnBusiness = [
  {
    Header: "Business name",
    accessor: "business_name",
  },
  {
    Header: "Email",
    accessor: "email_address",
  },
  {
    Header: "Business type",
    accessor: "business_type",
  },
  {
    Header: "Country",
    accessor: "country_name",
  },
  {
    Header: "Subsidiaries",
    accessor: "subsidiaries",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];
