import React, { useState, ReactNode, Fragment, useEffect } from "react";
import styles from "./style.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
    ToastSuccessStyles,
    ToastErrorStyles,
} from "../../../constants/toastStyles";
import PaymentModal from "../../ModalContent/PaymentModal/PaymentModal";
import OutlineTextInput from "../../input/OutlineTextInput";
import Select from "react-select";

const ApproveLimit = ({ serviceData, fetchServices, type }: any) => {
    const dispatch = useDispatch();
    const [referenceData, setReferenceData] = useState<any>();

    const [approvalStatus, setApprovalStatus] = useState<boolean>();
    const [menuOption, setMenuOption] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
    function closeModal() {
        setIsOpen(false);
    }
    const closeModalDetails = () => {
        setIsOpenDetails(false);
    };
    const handleClickAway = () => {
        setMenuOption(false);
    };
    const handleMenu = () => {
        setMenuOption(!menuOption);
    };


    interface initTypes {
        reason: string;
    }

    const initProps: initTypes = {
        reason: "",
    };
    const [inputs, setInputs] = React.useState(initProps);

    // const disableFunction = () => {
    //   if (inputs.firstname && inputs.role) {
    //     return true;
    //   }
    // };


    const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const inputName = event.target.name;
        setInputs((prevState: initTypes) => {
            return {
                ...prevState,
                [inputName]: newValue,
            };
        });
    };

    function capitalizeFirstLetter(string: string) {
        return string[0].toUpperCase() + string.slice(1);
    }
    const updatePaymentLink = async () => {
        try {
            const data: any = await client.post(
                `limits/approve`,
                {
                    id: serviceData?.id,
                    approved: approvalStatus,
                    type: capitalizeFirstLetter(type),
                    reason: inputs.reason,
                }
            );
            const message = data?.data?.message;
            fetchServices();
            closeModal();
            dispatch(
                openToastAndSetContent({
                    toastStyles: ToastSuccessStyles,
                    toastContent: message,
                })
            );
        } catch (error: any) {
            const message = error?.response?.data?.message;
            dispatch(
                openToastAndSetContent({
                    toastStyles: ToastErrorStyles,
                    toastContent: message,
                })
            );
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        updatePaymentLink();
    };

    return (
        <Fragment>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={styles.menuBody}>
                    <div className={styles.menuIcon} onClick={handleMenu}>
                        <MenuIcon />
                    </div>

                    {menuOption && (
                        <div className={styles.menuContent} onClick={handleClickAway}>
                            <Menus
                                icon={ViewIcon}
                                optionName="Approve Limit"
                                onclick={() => {
                                    setIsOpen(true)
                                    setApprovalStatus(true)
                                }}
                            />
                            <Menus
                                icon={ViewIcon}
                                optionName="Decline Limit"
                                onclick={() => {
                                    setIsOpen(true)
                                    setApprovalStatus(false)
                                }}
                            />
                        </div>
                    )}
                </div>
            </ClickAwayListener>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
            // className={styles.modal}
            >
                <div className={styles.modalBody}>
                    <div className={styles.modalHeader}>
                        <div className={styles.background}>
                            <div className={styles.linkDetails}>{approvalStatus ? "Approve" : "Reject"} Limit</div>
                            <CloseIcon onClick={closeModal} />
                        </div>
                    </div>
                    <div className={styles.businessForm}>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <OutlineTextInput
                                handleChange={updateProps}
                                inputName="reason"
                                inputLabel="Reason"
                                inputValue={inputs?.reason}
                                multiline
                            />

                        </form>
                    </div>
                    <div className={styles.modalFooter}>
                        <div className={styles.cancel} onClick={closeModal}>
                            Cancel
                        </div>
                        <div
                            className={styles.deactivate}
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            Update
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalIsOpenDetails}
                onRequestClose={closeModalDetails}
                contentLabel="Example Modal"
                style={customStyles}
            // className={styles.modal}
            >
                <PaymentModal
                    closeModal={closeModalDetails}
                    reference={referenceData?.data?.payment_link}
                />
            </Modal>
        </Fragment>
    );
};

export default ApproveLimit;
