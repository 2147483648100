import React, { useState, Fragment, useEffect } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  TransactionsData,
  ColumnTransactions
} from "../../types/TablesTypes/Transactions";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import ChargebackIcon from "../../assets/images/Icons/initiate-chargebacks.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  closeLoader,
  openLoader
} from "../../redux/actions/loader/loaderActions";

const tableStatus = [
  { id: 1, name: "Initiated" },
  { id: 2, name: "Pending" },
  { id: 3, name: "Awaiting-Confirmation" },
  { id: 4, name: "Failed" },
  { id: 5, name: "Reversed" },
  { id: 6, name: "Processing" },
  { id: 7, name: "Successful" }
];

const AllTransactions = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [status, setStatus] = useState<string>("");
  const [transactions, setTransactions] = useState<any>();
  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  const dispatch = useDispatch();
  const history = useHistory();

  const fetchTransactions = async () => {
    status.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(`order/all/paginated?status=${status}`);
      setTransactions(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [status]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View details";
      },
      icon: ViewIcon,
      onclick: (
        emailAddress: string,
        e: any,
        status: string,
        transactionId: number
      ) => {
        history.push(
          `/dashboard/transactions/transaction-details/${transactionId}`
        );
      }
    },
    {
      id: 2,
      name: (id: number) => {
        return "Initiate Chargeback";
      },
      icon: ChargebackIcon,
      onclick: (
        transactionEmail: string,
        e: React.SyntheticEvent,
        amount: string,
        transactionId: number
      ) => {
        {
          const payload = {
            order_id: transactionId,
            amount: amount
          };
          e.preventDefault();
          client
            .post("/refund/transaction", payload)
            .then((data: any) => {
              fetchTransactions();
              const message = data?.data?.message;
              dispatch(
                openToastAndSetContent({
                  toastStyles: ToastSuccessStyles,
                  toastContent: message
                })
              );
            })
            .catch((error: any) => {
              if (error.response) {
                const message = error?.response?.data?.message;
                dispatch(
                  openToastAndSetContent({
                    toastStyles: ToastErrorStyles,
                    toastContent: message
                  })
                );
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("Error", error.message);
              }
            });
        }
      }
    }
  ];

  const dataTransactions = () => {
    const tempArr: TransactionsData[] = [];
    transactions?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        return tempArr.push({
          date: moment(transaction?.date_created).format(
            "MMMM Do YYYY, h:mm a"
          ),
          customer_name: transaction?.customer_name,
          email: transaction?.customer_email,
          transaction_amount: `${transaction?.currency} ${transaction?.amount_formatted}`,
          payment_channel: transaction?.payment_type,
          status: (
            <StatusView
              status={transaction?.order_status}
              green="Successful"
              orange="Initiated"
              red="Failed"
              blue="Reversed"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          transaction?.email_address,
                          e,
                          transaction?.amount,
                          transaction?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(transaction?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(transaction?.order_status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransactions());
  }, [transactions?.items]);

  const itemLength = transactions?.items?.length;
  return (
    <Fragment>
      <TableHeader
        pageName="All Transactions"
        data={transactions?.items}
        dataLength={transactions?.items?.length}
        value={value}
        setValue={setValue}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              {tableStatus.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => {
                    setStatus(name);
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        }
      />

      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnTransactions ? ColumnTransactions : []}
          value={value}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any transactions"
              : "Loading..."
          }
        />
      )}
    </Fragment>
  );
};

export default AllTransactions;
