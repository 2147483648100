import React, { useState, Fragment, useEffect } from "react";
import styles from "./Business.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnBusiness, BusinessData } from "../../types/TablesTypes/Business";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import businessApi from "../../api/businesses";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import { useHistory } from "react-router-dom";
import CreateBusiness from "./CreateBusiness/CreateBusiness";
import {
  closeLoader,
  openLoader
} from "../../redux/actions/loader/loaderActions";

const tableStatus = [
  { id: 1, name: "New" },
  { id: 2, name: "Pending-Verification" }
];

const Business = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState<string>("");
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");

  const [businesses, setBusinesses] = useState<any>();
  const getBusinessApi = useApi(businessApi.getBusiness);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const fetchBusinesses = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `business/all/paginated?status=${status}`
      );
      setBusinesses(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchBusinesses();
  }, [status]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View business details";
      },
      icon: ViewIcon,
      onclick: (
        emailAddress: string,
        e: any,
        status: string,
        businessId: number
      ) => {
        history.push(`/dashboard/accounts/business-details/${businessId}`);
      }
    }
  ];

  const dataBusinesses = () => {
    const tempArr: BusinessData[] = [];
    businesses?.items
      ?.slice(0)
      .reverse()
      .forEach((business: any, index: number) => {
        return tempArr.push({
          business_name: business?.business_name,
          email_address: business?.email,
          business_type: business?.business_type,
          country_name: business?.country,
          subsidiaries: business?.no_of_subsidiaries,
          status: (
            <StatusView
              status={business?.status}
              red="New"
              orange="Pending-Verification"
              green="Active"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          business?.email_address,
                          e,
                          business?.status,
                          business?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(business?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(business?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataBusinesses());
  }, [businesses?.items]);

  const itemLength = businesses?.items?.length;

  return (
    <Fragment>
      <TableHeader
        pageName="Businesses"
        data={businesses?.items}
        dataLength={businesses?.items?.length}
        value={value}
        setValue={setValue}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Business Type</div>
            <div className={styles.filterBody}>
              {tableStatus.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => {
                    setStatus(name);
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        }
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <LinkIcon className={styles.link} />
            Add New Business
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateBusiness
          fetchBusinesses={fetchBusinesses}
          closeModal={closeModal}
        />
      </Modal>
      {getBusinessApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnBusiness ? ColumnBusiness : []}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
          value={value}
        />
      )}
    </Fragment>
  );
};

export default Business;
