import styles from "./Dashboard.module.scss";
import { companies } from "../../../utils/companies";
import { Divider } from "@mui/material";
import { ITopMerchant } from "./Dashboard";
import BrushStrokeLogo from "../../../assets/images/Logos/brushStroke.svg";

interface Iprops {
  data: ITopMerchant[];
}

const MostTransaction = ({ data = [] }: Iprops) => {
  const arranged = data.sort((a, b) =>
    a.total_count < b.total_count ? 1 : -1
  );

  return (
    <div>
      <div>
        <h6 className={styles.title}>Most transactions</h6>
        <p className={styles.description}>
          Merchants with the most amount of transactions
        </p>
        {arranged.map(({ logo, name, value, total_count }) => (
          <div key={name}>
            <div className={styles.flexCard}>
              <div className={styles.left}>
                {logo && (
                  <div className={styles.logo}>
                    <img src={logo} alt={name} width="40" height="40" />
                  </div>
                )}
                <div className={styles.name}>{name}</div>
              </div>
              <div className={styles.right}>{total_count}</div>
            </div>
            <Divider />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MostTransaction;
