import React, { useEffect } from "react";
import styles from "./Settlement.module.scss";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { settlementGroups } from "../../../utils/settlementGroups";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/Icons/edit-icon.svg";
import { ReactComponent as MemberIcon } from "../../../assets/images/Icons/member.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/images/Icons/calendar.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/Icons/arrow-forward.svg";
import { ReactComponent as SettlementIcon } from "../../../assets/images/Icons/settlement-groups-white.svg";
import { customStyles } from "../../../constants/modalStyles";
import settlementApi from "../../../api/settlementGroups";
import useApi from "../../../hooks/useApi";
import Modal from "react-modal";
import SettlementgroupsModal from "../../../components/ModalContent/SettlementcycleModal/SettlementgroupsModal";
import SettlementgroupMenu from "../../../components/MenuOption/SettlementcycleMenu/SettlementgroupMenu";
import currencyApi from "../../../api/currencies";
import subsidiaryApi from "../../../api/subsidiaries";
import settlementCycleApi from "../../../api/settlementCycle";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
  borderRadius: "12px",
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SettlementGroups = () => {
  const history = useHistory();

  const getSettlementGroupsApi = useApi(settlementApi.getSettlementGroups);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const fetchSettlementGroups = async () => {
    getSettlementGroupsApi.request();
  };
  const settlementGroups = getSettlementGroupsApi?.data?.items;
  const getCurrenciesApi = useApi(currencyApi.getCurrencies);

  const fetchCurrencies = async () => {
    getCurrenciesApi.request();
  };

  const currencies = getCurrenciesApi?.data?.data;

  const getSubsidiariesApi = useApi(subsidiaryApi.getSubsidiaries);

  const getSettlementCyclesApi = useApi(settlementCycleApi.getSettlementCycles);

  const fetchSettlementCycles = async () => {
    getSettlementCyclesApi.request();
  };

  const settlementPlans = getSettlementCyclesApi?.data?.settlement_plans;

  const fetchSubsidiaries = async () => {
    getSubsidiariesApi.request();
  };
  const subsidiaries = getSubsidiariesApi?.data?.items;

  useEffect(() => {
    fetchSettlementGroups();
    fetchCurrencies();
    fetchSubsidiaries();
    fetchSettlementCycles();
  }, []);

  return (
    <div className={styles.settlementBody}>
      <div className={styles.topSections}>
        <div className={styles.header}>Settlement groups</div>
        <div
          className={styles.chargeBack}
          onClick={() => setIsOpen(true)}
          style={{ padding: "4px" }}
        >
          <SettlementIcon />
          <span style={{ padding: "2px" }}>New settlement group</span>
        </div>
      </div>
      <div>
        <Box sx={{ flexGrow: 1, marginTop: "1rem" }}>
        <Grid container spacing={2}>
            {!!settlementGroups &&
              settlementGroups?.map((data: any, index: any) => {
                return (
                  <Grid item md={6} xs={12} lg={4} key={index}>
                    <Item key={data?.id}>
                      <div>
                        <div className={styles.sectionTop}>
                          <div className={styles.sectionTopLeft}>
                            <div className={styles.title}>
                              <h6 className={styles.bold}>{data?.name}</h6>
                              <h6 className={styles.currency}>
                                {data?.currency}
                              </h6>
                            </div>
                          </div>
                          <div className={styles.icon}>
                            <SettlementgroupMenu
                              id={data?.id}
                              settlementData={data}
                              fetchSettlementGroups={fetchSettlementGroups}
                              currencies={currencies}
                              selectedCurrency={data?.currency}
                              settlementPlans={settlementPlans}
                            />
                          </div>
                        </div>
                        <div className={styles.sectionBottom}>
                          <div className={styles.icons}>
                            <MemberIcon />
                            <span className={styles.text}>
                              Number of members : {data?.count_of_subsidiaries}
                            </span>
                          </div>
                          <div className={styles.icons}>
                            <CalendarIcon />
                            <span className={styles.text}>
                              Settlement cycle : {data?.settlement_cycle_name}
                            </span>
                          </div>
                          <div
                            className={styles.viewButton}
                            onClick={() =>
                              history.push(
                                `/dashboard/settings/settlement-groups/${data?.id}`
                              )
                            }
                          >
                            <span>View group</span>
                            <ArrowIcon />
                          </div>
                        </div>
                      </div>
                    </Item>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <SettlementgroupsModal
              fetchSettlementGroups={fetchSettlementGroups}
              closeModal={closeModal}
              currencies={currencies}
              subsidiaries={subsidiaries}
              settlementPlans={settlementPlans}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SettlementGroups;
