import React from "react";
import styles from "./Reset.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import { useHistory } from "react-router-dom";
import { Box, Button, TextField } from "@material-ui/core";
// import { useForm } from "react-hook-form";

const ResetPasswordModal = ({ closeModal }: any) => {
  // const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  interface initTypes {
    email: string;
  }

  const initProps: initTypes = {
    email: "",
  };

  const [inputs, setInputs] = React.useState(initProps);
  const [valid, setValid] = React.useState(false);
  const [value, setValue] = React.useState("");

  const disableFunction = () => {
    if (/\S+@\S+\.\S+/.test(inputs.email)) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleReset = async () => {
    try {
      const data: any = await client.post("forgot/password", inputs);
      const message = data?.data?.message;
      // fetchEmail();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
      history.push(`/forgot-password/${inputs.email}`);
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleReset();
  };

  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Reset Password</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="email"
            inputLabel="Email*"
            inputType="email"
            inputValue={inputs.email}
            InputHelper={
              value !== "/S+@S+.S+/" ? "Please enter a valid email!" : " "
            }
            notRequired={true}
            InputRef={{
              required: true,
              pattern: /\S+@\S+\.\S+/,
            }}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Reset Password
          </div>
        ) : (
          <div className={styles.notClickable}>Reset Password</div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordModal;
