import React from "react";

export interface ComplianceData {
  id: string;
  business_name: string;
  email: string;
  business_type: string;
  country_name: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnCompliance = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Business name",
    accessor: "business_name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Business type",
    accessor: "business_type",
  },
  {
    Header: "Country",
    accessor: "country_name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];
