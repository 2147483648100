import React from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  const todaysDate = new Date();
  return (
    <div>
      {" "}
      <div className={styles.sidebarFooter}>
        <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
        <div>All rights reserved</div>
      </div>
    </div>
  );
};

export default Footer;
