import React, { useState, Fragment, useEffect } from "react";
import styles from "./limit.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import TableHeader from "../../../components/TableHeader/TableHeader";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import usersApi from "../../../api/users";
import rolesApi from "../../../api/roles";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
    ToastSuccessStyles,
    ToastErrorStyles,
} from "../../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import {
    closeLoader,
    openLoader,
} from "../../../redux/actions/loader/loaderActions";
import moment from "moment";
import ServicesMenu from "../../../components/MenuOption/ServicesMenu/ServicesMenu";
import { PendingLimitsData, ColumnPendingLimits, PayoutLimitsData, ColumnPayoutLimits, PayoutLimitsDataCreate, ColumnPayoutLimitsCreate } from "../../../types/TablesTypes/limits";
import AddCollectionLimit from "../../../components/ModalContent/AddCollectionLimit/AddCollectionLimit";
import AddPayoutLimit from '../../../components/ModalContent/AddPayoutLimit/AddPayoutLimit';
import ApproveLimit from '../../../components/MenuOption/ApproveLimit/ApproveLimit';
import StatusView from '../../../components/StatusView/StatusView';
import meApi from "../../../api/me";


const PendingLimit = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [tableRow, setTableRow] = useState<any[]>();
    const [value, setValue] = useState("");
    const [role, setRole] = useState<string>("collection");
    const [services, setServices] = useState<any>();
    const getUsersApi = useApi(usersApi.getUsers);
    const [modalIsOpen, setIsOpen] = React.useState(false);


    const [modalIsOpen2, setIsOpen2] = React.useState(false);

    const getMeApi = useApi(meApi.getMe);

    useEffect(() => {
        getMeApi.request();
    }, []);


    const { subsidiaryInfo } = useSelector(
        (state) => state.subsidiaryInfoReducer
    );

    const adminPermission = getMeApi?.data?.user?.permission;


    console.log("adminPermission", adminPermission);



    function closeModal2() {
        setIsOpen2(false);
    }
    function closeModal() {
        setIsOpen(false);
    }

    const fetchServices = async () => {
        dispatch(openLoader());
        try {
            const { data }: any = await client.get(`limits/pending?type=${role}`);
            setServices(data?.data);
            dispatch(closeLoader());
        } catch (error: any) {
            dispatch(closeLoader());
            const message = error?.response?.data?.message;
            dispatch(
                openToastAndSetContent({
                    toastContent: message,
                    toastStyles: ToastErrorStyles,
                })
            );
        }
    };

    useEffect(() => {
        fetchServices();
    }, [role]);


    const dataServices = () => {
        const tempArr: PendingLimitsData[] = [];
        services?.items
            ?.slice(0)
            .reverse()
            .forEach((service: any, index: number) => {
                return tempArr.push({
                    currency: service?.currency,
                    minimum_amount: service?.minimum_amount,
                    maximum_amount: service?.maximum_amount,
                    cummulative_daily: service?.cummulative_daily,
                    subsidiary_name: service?.subsidiary_name,
                    subsidiary_email: service?.subsidiary_email,
                    is_approved: <StatusView status={service?.is_approved === true ? "Approved" : service?.is_approved === false ? "Rejected" : "Pending"} green={"Approved"} orange={"Pending"} red={"Rejected"} />
                    ,
                    action: (
                        <>
                            {
                                (service?.is_approved === true || service?.is_approved === false) ? null : adminPermission?.includes("Appprove limits") ? <ApproveLimit serviceData={service} fetchServices={fetchServices} type={role} /> : null
                            }
                        </>
                    ),
                });
            });
        return tempArr;
    };

    const dataServicesPayout = () => {
        const tempArr: PayoutLimitsDataCreate[] = [];
        services?.items
            ?.slice(0)
            .reverse()
            .forEach((service: any, index: number) => {
                return tempArr.push({
                    currency: service?.currency,
                    frequency: service?.frequency,
                    transfer_limit: service?.transfer_limit,
                    cummulative_daily: service?.cummulative_daily,
                    subsidiary_name: service?.subsidiary_name,
                    subsidiary_email: service?.subsidiary_email,
                    is_approved: <StatusView status={service?.is_approved === true ? "Approved" : service?.is_approved === false ? "Rejected" : "Pending"} green={"Approved"} orange={"Pending"} red={"Rejected"} />
                    ,
                    action: (
                        <>
                            {
                                service?.is_approved === true || service?.is_approved === false ? null : <ApproveLimit serviceData={service} fetchServices={fetchServices} type={role} />
                            }
                        </>
                    ),
                });
            });
        return tempArr;
    };

    useEffect(() => {
        role === "collection" ? setTableRow(dataServices()) : setTableRow(dataServicesPayout());
    }, [services]);

    const rolesData = [
        {
            id: 1,
            name: "collection",
        },
        {
            id: 1,
            name: "payout",
        },

    ]

    const itemLength = services?.items?.length;

    //Add/update limits, Appprove limits
    return (
        <Fragment>
            <TableHeader
                pageName="Pending Limits"
                data={services?.items}
                dataLength={services?.items?.length}
                value={value}
                // noShowFilter={true}
                setValue={setValue}
                newButton={
                    <div className={styles.limitSection}>
                        {
                            adminPermission?.includes("Add/update limits") &&
                            <>
                                <div className={styles.chargeBack} onClick={() => setIsOpen(true)}>
                                    <span style={{ padding: "2px" }}>Add Collection Limit</span>
                                </div>
                                <div className={styles.chargeBack} onClick={() => setIsOpen2(true)}>
                                    <span style={{ padding: "2px" }}>Add Payout Limit</span>
                                </div>
                            </>
                        }

                    </div>
                }
                FilterComponent={
                    <div>
                        <div className={styles.filterSubHeading}>Types</div>
                        <div className={styles.filterBody}>
                            {rolesData?.map((role: any, index: number) => (
                                <div
                                    key={role?.id}
                                    onClick={() => {
                                        setRole(role?.name);
                                    }}
                                >
                                    {role?.name}
                                </div>
                            ))}
                        </div>
                    </div>
                }
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <AddCollectionLimit fetchServices={fetchServices} closeModal={closeModal} />
            </Modal>
            <Modal
                isOpen={modalIsOpen2}
                onRequestClose={closeModal2}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <AddPayoutLimit fetchServices={fetchServices} closeModal={closeModal2} />
            </Modal>
            {getUsersApi.loading ? (
                <div className={styles.loading} />
            ) : (
                <>
                    {role === "collection" ? (
                        <PaginationTable
                            data={tableRow ? tableRow : []}
                            columns={ColumnPendingLimits ? ColumnPendingLimits : []}
                            value={value}
                            emptyPlaceHolder={
                                itemLength === 0
                                    ? "You currently do not have limits set"
                                    : "Loading..."
                            }
                        />) : (
                        <PaginationTable
                            data={tableRow ? tableRow : []}
                            columns={ColumnPayoutLimitsCreate ? ColumnPayoutLimitsCreate : []}
                            value={value}
                            emptyPlaceHolder={
                                itemLength === 0
                                    ? "You currently do not have limits set"
                                    : "Loading..."
                            }
                        />)}
                </>
            )}

            <Footer />
        </Fragment>
    );
};

export default PendingLimit;
