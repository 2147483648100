import React from "react";

export interface SettlementsTableData {
  business_name: string;
  settlement_cycle_name: string;
  count_of_subsidiaries: number;
  currency: string;
  date_created: string;
  cycle_days: number;
}

export const ColumnSettlements = [
  {
    Header: "Business name",
    accessor: "business_name",
  },
  {
    Header: "Settlement Cycle Name",
    accessor: "settlement_cycle_name",
  },
  {
    Header: "Count of Subsidiaries",
    accessor: "count_of_subsidiaries",
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Date Created",
    accessor: "date_created",
  },
  {
    Header: "Cycle Days",
    accessor: "cycle_days",
  },
];
