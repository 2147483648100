import React, { useState, Fragment, useEffect } from "react";
import styles from "./limit.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import TableHeader from "../../../components/TableHeader/TableHeader";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import usersApi from "../../../api/users";
import rolesApi from "../../../api/roles";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
    ToastSuccessStyles,
    ToastErrorStyles,
} from "../../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import CreateCustomer from "../../../components/ModalContent/CreateCustomer/CreateCustomer";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import PaymentLinkMenu from "../../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import UserMenu from "../../../components/MenuOption/UserMenu/UserMenu";
import {
    closeLoader,
    openLoader,
} from "../../../redux/actions/loader/loaderActions";
import moment from "moment";
import ServicesMenu from "../../../components/MenuOption/ServicesMenu/ServicesMenu";
import { CollectionLimitsData, ColumnCollectionLimits } from "../../../types/TablesTypes/limits";
import AddCollectionLimit from "../../../components/ModalContent/AddCollectionLimit/AddCollectionLimit";

const CollectionLimit = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [tableRow, setTableRow] = useState<any[]>();
    const [value, setValue] = useState("");
    const [role, setRole] = useState<string>("");
    const [services, setServices] = useState<any>();
    const getUsersApi = useApi(usersApi.getUsers);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function closeModal() {
        setIsOpen(false);
    }

    const fetchServices = async () => {
        dispatch(openLoader());
        try {
            const { data }: any = await client.get(`limits/collection`);
            setServices(data?.data);
            dispatch(closeLoader());
        } catch (error: any) {
            dispatch(closeLoader());
            const message = error?.response?.data?.message;
            dispatch(
                openToastAndSetContent({
                    toastContent: message,
                    toastStyles: ToastErrorStyles,
                })
            );
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);


    const dataServices = () => {
        const tempArr: CollectionLimitsData[] = [];
        services?.items
            ?.slice(0)
            .reverse()
            .forEach((service: any, index: number) => {
                return tempArr.push({
                    currency: service?.currency,
                    minimum_amount: service?.minimum_amount,
                    maximum_amount: service?.maximum_amount,
                    cummulative_daily: service?.cummulative_daily,
                    subsidiary_name: service?.subsidiary_name,
                    subsidiary_email: service?.subsidiary_email,
                    // action: (
                    //     <ApproveLimit serviceData={service} fetchServices={fetchServices} type={role} />
                    // ),
                });
            });
        return tempArr;
    };

    useEffect(() => {
        setTableRow(dataServices());
    }, [services]);

    const itemLength = services?.items?.length;
    return (
        <Fragment>
            <TableHeader
                pageName="Limits"
                data={services?.items}
                dataLength={services?.items?.length}
                value={value}
                noShowFilter={true}
                setValue={setValue}
            // newButton={
            //     <div className={styles.chargeBack} onClick={() => setIsOpen(true)}>
            //         <span style={{ padding: "2px" }}>Add Limit</span>
            //     </div>
            // }
            // FilterComponent={
            //   <div>
            //     <div className={styles.filterSubHeading}>Roles</div>
            //     <div className={styles.filterBody}>
            //       {rolesData?.map((role: any, index: number) => (
            //         <div
            //           key={role?.id}
            //           onClick={() => {
            //             setRole(role?.name);
            //           }}
            //         >
            //           {role?.name}
            //         </div>
            //       ))}
            //     </div>
            //   </div>
            // }
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <AddCollectionLimit fetchServices={fetchServices} closeModal={closeModal} />
            </Modal>
            {getUsersApi.loading ? (
                <div className={styles.loading} />
            ) : (
                <PaginationTable
                    data={tableRow ? tableRow : []}
                    columns={ColumnCollectionLimits ? ColumnCollectionLimits : []}
                    value={value}
                    emptyPlaceHolder={
                        itemLength === 0
                            ? "You currently do not have limits"
                            : "Loading..."
                    }
                />
            )}

            <Footer />
        </Fragment>
    );
};

export default CollectionLimit;
