import React from "react";

export interface UsersData {
  name: string;
  role: string;
  email_address: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnUsers = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Email Address",
    accessor: "email_address",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];
