import React, { useState, Fragment, useEffect } from "react";
import styles from "./Services.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnServices,
  ServicesData,
} from "../../../types/TablesTypes/Services";
import MenuOption from "../../../components/MenuOption/MenuOption";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import usersApi from "../../../api/users";
import rolesApi from "../../../api/roles";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import CreateCustomer from "../../../components/ModalContent/CreateCustomer/CreateCustomer";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import PaymentLinkMenu from "../../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import UserMenu from "../../../components/MenuOption/UserMenu/UserMenu";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import moment from "moment";
import ServicesMenu from "../../../components/MenuOption/ServicesMenu/ServicesMenu";

const Services = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [role, setRole] = useState<string>("");
  const [services, setServices] = useState<any>();
  const getUsersApi = useApi(usersApi.getUsers);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const fetchServices = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(`services/providers/list`);
      console.log(data);
      setServices(data?.data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const dataServices = () => {
    const tempArr: ServicesData[] = [];
    services
      ?.slice(0)
      .reverse()
      .forEach((service: any, index: number) => {
        return tempArr.push({
          payment_option: service?.payment_option,
          payment_option_name: service?.payment_option_name,
          provider: service?.provider,
          date_created: moment(service?.date_created).format(
            "MMMM Do YYYY, h:mm a"
          ),
          date_updated:
            moment(service?.date_updated).format("MMMM Do YYYY, h:mm a") ||
            "N/A",

          action: (
            <ServicesMenu serviceData={service} fetchServices={fetchServices} />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataServices());
  }, [services]);

  const itemLength = services?.length;
  return (
    <Fragment>
      <TableHeader
        pageName="Services"
        data={services}
        dataLength={services?.length}
        value={value}
        noShowFilter={true}
        setValue={setValue}
        // FilterComponent={
        //   <div>
        //     <div className={styles.filterSubHeading}>Roles</div>
        //     <div className={styles.filterBody}>
        //       {rolesData?.map((role: any, index: number) => (
        //         <div
        //           key={role?.id}
        //           onClick={() => {
        //             setRole(role?.name);
        //           }}
        //         >
        //           {role?.name}
        //         </div>
        //       ))}
        //     </div>
        //   </div>
        // }
      />
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateCustomer fetchCustomers={fetchUsers} closeModal={closeModal} />
      </Modal> */}
      {getUsersApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnServices ? ColumnServices : []}
          value={value}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
        />
      )}

      <Footer />
    </Fragment>
  );
};

export default Services;
