import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./ComplianceDetails.module.scss";
import client from "../../../api/client";
import Footer from "../../../components/footer/Footer";
import { useParams } from "react-router-dom";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import ComplianceDetailsProfile from "./ComplianceDetailsProfile";
import ComplianceDetailsBank from "./ComplianceDetailsBank";
import ComplianceDetailsDirectors from "./ComplianceDetailsDirectors";
import ComplianceDetailsKYC from "./ComplianceDetailsKYC";
import ApproveIcon from "../../../assets/images/Icons/approve-white.svg";
import RejectIcon from "../../../assets/images/Icons/reject.svg";
import RejectCompliance from "./RejectCompliance/RejectCompliance";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Grid from "@mui/material/Grid";
import useApi from "../../../hooks/useApi";
import meApi from "../../../api/me";

const ComplianceDetail = () => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useDispatch();

  const [current, setCurrent] = useState(1);
  const [profileDetails, setProfileDetails] = useState<any>();
  const [bankDetails, setBankDetails] = useState<any>();
  const [directorsInfo, setDirectorsInfo] = useState<any>();
  const [kycDetails, setKycDetails] = useState<any>();

  const [compliance, setCompliance] = useState<any>();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const fetchKYCRequest = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(`/kyc/subsidiary/all?id=${id}`);
      setCompliance(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchKYCRequest();
  }, []);

  const viewProfile = async (subId: any) => {
    dispatch(openLoader());
    try {
      const data: any = await client.get(
        `/kyc/subsidiary/profile/view?subsidiaryid=${subId}`
      );
      dispatch(closeLoader());
      const message = data?.data?.message;
      setProfileDetails(data?.data?.data);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const viewBankDetails = async (subId: any) => {
    dispatch(openLoader());
    try {
      const data: any = await client.get(
        `/kyc/subsidiary/bank/view?subsidiaryid=${subId}`
      );
      dispatch(closeLoader());
      const message = data?.data?.message;
      setBankDetails(data?.data?.data);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const viewDirectorsInfo = async (subId: any) => {
    dispatch(openLoader());
    try {
      const data: any = await client.get(
        `/kyc/subsidiary/director/view?subsidiaryid=${subId}`
      );
      dispatch(closeLoader());
      const message = data?.data?.message;
      setDirectorsInfo(data?.data?.data);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const viewKycDetails = async (subId: any) => {
    dispatch(openLoader());
    try {
      const data: any = await client.get(
        `/kyc/subsidiary/details/view?subsidiaryid=${subId}`
      );
      dispatch(closeLoader());
      const message = data?.data?.message;
      setKycDetails(data?.data?.data);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const complianceData = [
    {
      id: 1,
      name: "Profile",
      component: <ComplianceDetailsProfile profileDetails={profileDetails} />
    },
    {
      id: 2,
      name: "Bank Details",
      component: <ComplianceDetailsBank bankDetails={bankDetails} />
    },
    {
      id: 3,
      name: "Director's Information",
      component: <ComplianceDetailsDirectors directorsInfo={directorsInfo} />
    },
    {
      id: 4,
      name: "KYC Details",
      component: <ComplianceDetailsKYC kycDetails={kycDetails} />
    }
  ];

  useEffect(() => {
    viewProfile(id);
    viewBankDetails(id);
    viewDirectorsInfo(id);
    viewKycDetails(id);
  }, [id]);

  const payload = {
    subsidiary_id: parseInt(id),
    reason: null,
    status: "Approved"
  };

  const handleApprove = (e: React.SyntheticEvent) => {
    e.preventDefault();
    client
      .post("/kyc/subsidiary/verify", payload)
      .then((data: any) => {
        const message = data?.data?.message;
        viewProfile(id);
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: message
          })
        );
      })
      .catch((error: any) => {
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const handleFinal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    client
      .post("/kyc/subsidiary/final/verification", payload)
      .then((data: any) => {
        const message = data?.data?.message;
        viewProfile(id);
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: message
          })
        );
      })
      .catch((error: any) => {
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  const getMeApi = useApi(meApi.getMe);

  useEffect(() => {
    getMeApi.request();
  }, []);

  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const merchantStatus = profileDetails?.status;
  const adminPermission = getMeApi?.data?.user?.permission;

  const bvnStatus = profileDetails?.bvn_verified;

  return (
    <div className={styles.rolesBody}>
      <div>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12} lg={4}>
            <div className={styles.sidenav}>
              <div className={styles.header}>
                <span>KYC Requests & Compliance</span>
              </div>

              <div className={styles.mt1}>
                {complianceData?.map(({ id, name }) => (
                  <div key={id}>
                    <div
                      className={styles.sideItem}
                      onClick={() => setCurrent(id)}>
                      {name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item md={4} xs={12} lg={4}>
            <div className={styles.mt3}>
              <div className={styles.rolesDiv}>
                <div className={styles.rolesForm}>
                  {complianceData[current - 1].component}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4} xs={12} lg={4}>
            <div className={styles.buttonDiv}>
              {adminPermission?.includes("Accept/decline KYC requests") &&
                merchantStatus !== "Final-Review" && (
                  <div className={styles.reject}>
                    {bvnStatus ? (
                      <div
                        className={styles.buttonText}
                        onClick={() => setIsOpen(true)}>
                        <img src={RejectIcon} alt="Reject Icon" />
                        <span>Decline KYC request</span>
                      </div>
                    ) : (
                      <div
                        className={styles.buttonTextDisabled}
                        style={{
                          cursor: "not-allowed",
                          opacity: "0.5" 
                        }}>
                        <img src={RejectIcon} alt="Reject Icon" />
                        <span>Decline KYC request</span>
                      </div>
                    )}
                  </div>
                )}
              {adminPermission?.includes("Accept/decline KYC requests") &&
                merchantStatus !== "Final-Review" && (
                  <div className={styles.chargeBack}>
                    {bvnStatus ? (
                      <div
                        className={styles.buttonText}
                        onClick={handleApprove}>
                        <img src={ApproveIcon} alt="Approve Icon" />
                        <span>Accept KYC request</span>
                      </div>
                    ) : (
                      <div
                        className={styles.buttonText}
                        style={{
                          cursor: "not-allowed",
                          opacity: "0.5"
                        }}>
                        <img src={ApproveIcon} alt="Approve Icon" />
                        <span>Accept KYC request</span>
                      </div>
                    )}
                  </div>
                )}

              {adminPermission?.includes("Final KYC approval") &&
                merchantStatus === "Final-Review" && (
                  <div className={styles.reject}>
                    <div
                      className={styles.buttonText}
                      onClick={() => setIsOpen(true)}>
                      <img src={RejectIcon} alt="Reject Icon" />
                      <span>Decline KYC request</span>
                    </div>
                  </div>
                )}

              {adminPermission?.includes("Final KYC approval") &&
                merchantStatus === "Final-Review" && (
                  <div className={styles.chargeBack} onClick={handleFinal}>
                    <div className={styles.buttonText}>
                      <img src={ApproveIcon} alt="Approve Icon" />
                      <span>Approve KYC request</span>
                    </div>
                  </div>
                )}
            </div>
          </Grid>
        </Grid>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          style={customStyles}>
          <RejectCompliance
            rejectId={id}
            merchantName={profileDetails?.legal_business_name}
            status={profileDetails?.status}
            fetchKYCRequest={fetchKYCRequest}
            closeModal={closeModal}
          />
        </Modal>

        <Footer />
      </div>
    </div>
  );
};

export default ComplianceDetail;
