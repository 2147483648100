import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import styles from "./Profilebutton.module.scss";
import ProfileIcon from "../../assets/images/Icons/profile2.svg";
import SignOutIcon from "../../assets/images/Icons/sign-out.svg";
import UserImage from "../../assets/images/Logos/user-image.png";
import { makeStyles } from "@material-ui/styles";
import meApi from "../../api/me";
import useApi from "../../hooks/useApi";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

interface user {
  email: string;
  firstname: string;
  lastname: string;
}

export default function ProfileButton() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  const useStyles = makeStyles({
    root: {
      "&:hover": {
        background: "none",
      },
    },
    list: {
      backgroundColor: "#ffffff",
      overflow: "hidden",
      color: "rgba(0, 40, 65, 0.8)",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: "0",
    },
    primary: {
      fontSize: "212px",
    },
    paper: {
      boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.2)",
    },
  });
  const classes = useStyles();

  const handleProfile = () => {
    history.push("/dashboard/settings/profile");
    setAnchorEl(null);
  };

  const signOutHandler = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const getMeApi = useApi(meApi.getMe);

  useEffect(() => {
    getMeApi.request();
  }, []);


  const { navbarRoute } = useSelector((state) => state.navbarReducer);
  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const { avatar, first_name, email_address, last_name, mobile_number, role } =
    subsidiaryInfo?.data?.user;

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className={styles.user_description_image}>
          <img
            src={avatar ? avatar : UserImage}
            height={35}
            width={35}
            className={styles.userImage}
            alt="user"
          />
        </div>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        classes={{
          list: classes.list,
          paper: classes.paper,
          root: classes.root,
        }}
      >
        <div className={styles.profileDetail}>
          <div>
            <img
              src={avatar ? avatar : UserImage}
              height={30}
              width={30}
              className={styles.userImage}
              alt="user"
            />
          </div>
          <div className={styles.profileName}>
            <div className={styles.name}>
              {first_name} {last_name}
            </div>
            <div className={styles.email}>{email_address}</div>
            <div className={styles.role}>{role}</div>
          </div>
        </div>
        <Divider />
        <MenuItem>
          <div className={styles.profile} onClick={handleProfile}>
            <div>
              <img
                src={ProfileIcon}
                height={30}
                width={30}
                alt="Profile Icon"
              />
            </div>
            <div className={styles.profileDetail}>Profile</div>
          </div>
        </MenuItem>

        <MenuItem>
          <div onClick={signOutHandler} className={styles.profile}>
            <div>
              <img
                src={SignOutIcon}
                height={30}
                width={30}
                alt="Sign Out Icon"
              />
            </div>
            <div className={styles.signOut}>Sign Out</div>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
}
