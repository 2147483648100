import React, { useState, Fragment, useEffect } from "react";
import styles from "./Users.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ColumnUsers, UsersData } from "../../../types/TablesTypes/Users";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import usersApi from "../../../api/users";
import rolesApi from "../../../api/roles";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import CreateCustomer from "../../../components/ModalContent/CreateCustomer/CreateCustomer";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import PaymentLinkMenu from "../../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import UserMenu from "../../../components/MenuOption/UserMenu/UserMenu";
import {
  closeLoader,
  openLoader
} from "../../../redux/actions/loader/loaderActions";

const tableRoles = [
  { id: 1, name: "Admin" },
  { id: 2, name: "Compliance" },
  { id: 3, name: "Settlement Ops" }
];

const User = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [userStatus, setUserStatus] = useState<string>("");
  const [users, setUsers] = useState<any>();
  const getUsersApi = useApi(usersApi.getUsers);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const [rolesData, setRolesData] = useState<any>();

  const fetchUsers = async () => {
    // role.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `users/all/paginated?status=${userStatus}`
      );
      setUsers(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [userStatus]);

  const fetchRoles = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(`role/users`);
      setRolesData(data?.data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const status = ["Active", "InActive", "New"];
  const dataUsers = () => {
    const tempArr: UsersData[] = [];
    users?.items
      ?.slice(0)
      .reverse()
      .forEach((user: any, index: number) => {
        return tempArr.push({
          name: `${user?.first_name} ${user?.last_name}`,
          role: user?.role,
          email_address: user?.email_address,
          status: (
            <StatusView
              status={user?.status}
              green="Active"
              red="InActive"
              orange="New"
            />
          ),
          action: (
            <UserMenu
              firstName={user?.first_name}
              lastName={user?.last_name}
              role={user?.role}
              status={user?.status}
              fetchUsers={fetchUsers}
              userId={user?.id}
              userStatus={user?.status}
              roles={rolesData}
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataUsers());
  }, [users?.items]);

  const itemLength = users?.items?.length;
  return (
    <Fragment>
      <TableHeader
        pageName="Users/Admins"
        data={users?.items}
        dataLength={users?.items?.length}
        value={value}
        setValue={setValue}
        newButton={
          <div className={styles.chargeBack} onClick={() => setIsOpen(true)}>
            <span style={{ padding: "2px" }}>Add user</span>
          </div>
        }
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              {status?.map((status: any, index: number) => (
                <div
                  key={status}
                  onClick={() => {
                    setUserStatus(status);
                  }}
                >
                  {status}
                </div>
              ))}
            </div>
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateCustomer
          fetchCustomers={fetchUsers}
          closeModal={closeModal}
          role={rolesData}
        />
      </Modal>
      {getUsersApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnUsers ? ColumnUsers : []}
          value={value}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any customers"
              : "Loading..."
          }
        />
      )}

      <Footer />
    </Fragment>
  );
};

export default User;
