import React from "react";

export interface ChargebacksData {
  customer_name: string;
  email_address: string;
  transaction_amount: string;
  merchant_name: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnChargebacks = [
  {
    Header: "Customer Name",
    accessor: "customer_name"
  },
  {
    Header: "Email Address",
    accessor: "email_address"
  },
  {
    Header: "Transaction Amount",
    accessor: "transaction_amount"
  },
  {
    Header: "Merchant Name",
    accessor: "merchant_name"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "",
    accessor: "action"
  }
];

export interface ChargebacksOrdersData {
  merchant_name: string;
  customer_email: string;
  transaction_amount: string;
  transaction_reference: string;
  date_created: string;
  due_date: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnChargebacksOrders = [
  {
    Header: "Merchant Name",
    accessor: "merchant_name"
  },
  {
    Header: "Customer Email",
    accessor: "customer_email"
  },
  {
    Header: "Transaction Amount",
    accessor: "transaction_amount"
  },
  {
    Header: "Transaction Reference",
    accessor: "transaction_reference"
  },
  {
    Header: "Date Created",
    accessor: "date_created"
  },
  {
    Header: "Due Date",
    accessor: "due_date"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "",
    accessor: "action"
  }
];
