import React, { useEffect, useRef, useState } from "react";
import styles from "./bulk.module.scss";
// import { ReactComponent as CloseIcon } from "../../assets/images/Icons/closenew.svg";
import Select from "react-select";
import OutlineTextInput from "../input/OutlineTextInput";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import { ReactComponent as CloseIcon } from "../../assets/images/Icons/close.svg";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../constants/toastStyles";
import { useDispatch } from "react-redux";
import downloady from "../../assets/images/Icons/downloady.svg";
import { useHistory } from "react-router-dom";
import MultipleUpload from "./MultipleUpload";

interface dataComingTypes {
  currency: string;
  reference: string;
  amount: number;
  frequency: number;
  reason: string;
  due_date: string;
  type: string;
}

function Bulk({ closeModal, setBearer, fetchChargebacks }: any) {
  const [file, setFile] = useState<dataComingTypes[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const uploadMultipleFile = async () => {
    if (file && file?.length < 1)
      return window.alert("please, add a chargeback");
    try {
      const data: any = await client.post("chargeback/upload", file);

      const message = data?.data?.message;
      fetchChargebacks();
      closeModal();
      setBearer(true);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Upload Bulk Chargeback</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <MultipleUpload
          onDrop={(result: any) => {
            setFile(result);
          }}
        />

        <a
          href="https://docs.google.com/spreadsheets/d/1V_sn6FDYXlADUeOCrk15sZG1hFBmFDHxScgifWgM35E/edit?usp=sharing"
          download
          className={styles.modaluploadp}
        >
          Download sample CSV file for bulk refund
        </a>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        <div className={styles.deactivate} onClick={uploadMultipleFile}>
          Continue
        </div>
      </div>
    </div>
  );
}

export default Bulk;
