import React from "react";
import styles from "./Settlement.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const isNumbers = (str: string) => /^[0-9]*$/.test(str);

const SettlementcycleModal = ({ closeModal, fetchSettlementCycles }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    plan_name: string;
    description: string;
    full_payment_days: number;
  }

  const initProps: initTypes = {
    plan_name: "",
    description: "",
    full_payment_days: 0,
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [errorText, setErrorText] = React.useState("");
  const [touchedName, setTouchedName] = React.useState(false);
  const [touchedNumber, setTouchNumber] = React.useState(false);
  const [touchedDescription, setTouchDescription] = React.useState(false);

  const disableFunction = () => {
    if (inputs.plan_name && inputs.full_payment_days && inputs.description) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isAlphabets(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue,
        };
      });
    } else {
      setErrorText("Please input alphabets only");
    }
  };

  const updatePropsNumbers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isNumbers(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue,
        };
      });
    } else {
      setErrorText("Please input numbers only");
    }
  };

  const createPaymentLink = async () => {
    try {
      const data: any = await client.post("settlement/plan/create", inputs);
      const message = data?.data?.message;
      fetchSettlementCycles();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleTouchName = () => {
    setTouchNumber(false);
    setTouchDescription(false);
    setTouchedName(true);
  };

  const handeTouchNumber = () => {
    setTouchedName(false);
    setTouchDescription(false);
    setTouchNumber(true);
  };

  const handleTouchDescription = () => {
    setTouchedName(false);
    setTouchNumber(false);
    setTouchDescription(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (inputs.plan_name.length >= 3 && inputs.description.length >= 3) {
      createPaymentLink();
    } else {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: "Please input at least 3 alphabets",
        })
      );
    }
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New Settlement Cycle</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="plan_name"
            inputLabel="Cycle name"
            inputValue={inputs.plan_name}
            onFocus={handleTouchName}
            InputHelper={touchedName && errorText}
            error={touchedName && errorText}
          />
          <OutlineTextInput
            handleChange={updatePropsNumbers}
            inputName="full_payment_days"
            inputLabel="Number of Days"
            inputValue={inputs.full_payment_days}
            onFocus={handeTouchNumber}
            InputHelper={touchedNumber && errorText}
            error={touchedNumber && errorText}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
            onFocus={handleTouchDescription}
            InputHelper={touchedNumber && errorText}
            error={touchedNumber && errorText}
            multiline
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create
          </div>
        ) : (
          <div className={styles.notClickable}>Create </div>
        )}
      </div>
    </div>
  );
};

export default SettlementcycleModal;
