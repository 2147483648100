import React, { useEffect, useState } from "react";
import styles from "./ComplianceDetails.module.scss";
import { useDispatch } from "react-redux";
import client from "../../../api/client";
import { useParams } from "react-router-dom";
import {
  closeLoader,
  openLoader
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";

const ComplianceDetailsKYC = ({ kycDetails }: any) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormLabel-root": {
        zIndex: "0"
      }
    }
  }));

  const classes = useStyles();

  const kyc = {
    identification: kycDetails?.identification,
    proof_of_address: kycDetails?.proof_of_address
  };

  return (
    <div className={styles.roles}>
      <Box>
        <div>
          <div>
            <h5 className={styles.labelImage}>Means of identification</h5>
            {!!kyc?.identification ? (
              <div className={styles.validId}>
                <img
                  src={kyc?.identification}
                  width={80}
                  height={80}
                  alt="valid id"
                />
              </div>
            ) : (
              <div>No Valid Id yet</div>
            )}

            {/* <TextField
              className={classes.root}
              label="Means of identification"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {!!kyc?.identification
                      ? kyc?.identification
                      : "Subsidiary has not completed KYC"}
                  </InputAdornment>
                )
              }}
              disabled={true}
            /> */}
            <h5 className={styles.labelImage}>Proof of address</h5>
            {!!kyc?.proof_of_address ? (
              <div className={styles.validId}>
                <img
                  src={kyc?.proof_of_address}
                  width={80}
                  height={80}
                  alt="valid id"
                />
              </div>
            ) : (
              <div>No Proof of Address yet</div>
            )}
            {/* <TextField
              className={classes.root}
              label="Proof of address"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {kyc?.proof_of_address === null ||
                    kyc?.proof_of_address === ""
                      ? "Subsidiary has not completed KYC"
                      : kyc?.proof_of_address}
                  </InputAdornment>
                )
              }}
              disabled={true}
            /> */}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ComplianceDetailsKYC;
