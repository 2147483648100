import React from "react";
import styles from "./Chart.module.scss";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import ChartGraph from "../ChartGraph/Chart";
import ExportButton from "../ExportButton/ExportButton";
import { ChartData } from "../../constants/chartData";
import FilterButton from "../FilterButton/FilterButton";

const Chart = ({ inflowData, filterParam, setFilterParam }: any) => {
  return (
    <div>
      <div className={styles.transactionContent}>
        <div className={styles.transactionDetails}>
          <div className={styles.transactions}>Inflow</div>
          <div className={styles.flow}>Track inflow of money over time</div>
        </div>
        {filterParam === "year" ? (
          <div className={styles.timeLine}>
            <FilterButton time="This year" setFilterParam={setFilterParam} />
            <ExportButton data={inflowData} fileName="Transactions Flow" />
          </div>
        ) : (
          <div className={styles.timeLine}>
            <FilterButton time="This week" setFilterParam={setFilterParam} />
            <ExportButton data={inflowData} fileName="Transactions Flow" />
          </div>
        )}
      </div>
      <div className={styles.total}>
        <div className={styles.inflow}>
          <div className={styles.orange} />
          <span>Total inflow</span>
        </div>
      </div>
      <div className={styles.chartGraph}>
        <ChartGraph inflowData={inflowData} />
      </div>
    </div>
  );
};

export default Chart;
