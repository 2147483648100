import React, { useState, Fragment, useEffect } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnChargebacks,
  ChargebacksData
} from "../../types/TablesTypes/Chargebacks";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import ChargebackMenu from "../../components/MenuOption/SettlementMenu/ChargebackMenu";
import {
  closeLoader,
  openLoader
} from "../../redux/actions/loader/loaderActions";
import ChargebackButton from "./ChargebackButton/ChargebackButton";

const tableStatus = [
  { id: 1, name: "Initiated" },
  { id: 2, name: "Successful" },
  { id: 3, name: "Failed" }
];

const Orders = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [status, setStatus] = useState<string>("");
  const [transactionsData, setTransactionsData] = useState<any>();
  const getTransactionsApi = useApi(transactionsApi.getTransactions);
  const dispatch = useDispatch();

  const fetchChargebacks = async () => {
    status.replace(/-|\s/g, "");
    dispatch(openLoader());
    try {
      const { data } = await client.get(`order/all/paginated?status=${status}`);
      setTransactionsData(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchChargebacks();
  }, [status]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return id ? "View chargeback details" : null;
      },
      icon: ViewIcon,
      onclick: () => {}
    }
  ];

  const dataChargebacks = () => {
    const tempArr: ChargebacksData[] = [];
    transactionsData?.items
      ?.slice(0)
      .reverse()
      .forEach((chargebacks: any, index: number) => {
        return tempArr.push({
          customer_name: chargebacks?.customer_name,
          email_address: chargebacks?.customer_email,
          transaction_amount: chargebacks?.amount,
          merchant_name: chargebacks?.subsidiary_name,
          status: (
            <StatusView
              status={chargebacks?.order_status}
              green="Successful"
              red="Failed"
              orange="Initiated"
            />
          ),
          action: (
            <ChargebackMenu
              id={chargebacks?.customer_id}
              menuData={chargebacks}
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataChargebacks());
  }, [transactionsData?.items]);

  const itemLength = transactionsData?.items?.length;
  return (
    <Fragment>
      <TableHeader
        pageName="Orders"
        data={transactionsData?.items}
        dataLength={transactionsData?.items?.length}
        value={value}
        setValue={setValue}
        newButton={<ChargebackButton fetchChargebacks={fetchChargebacks} />}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              {tableStatus.map(({ id, name }) => (
                <div
                  key={id}
                  onClick={() => {
                    setStatus(name);
                  }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        }
      />

      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnChargebacks ? ColumnChargebacks : []}
          value={value}
          emptyPlaceHolder={
            itemLength == 0
              ? "You currently do not have any disputes"
              : "Loading..."
          }
        />
      )}
    </Fragment>
  );
};

export default Orders;
