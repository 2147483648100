import React, { Fragment, useState } from "react";
import Modal from "react-modal";
import styles from "./Chargeback.module.scss";
import { ReactComponent as DropArrow } from "../../../assets/images/Icons/drop-arrow.svg";
import { exportToXLS } from "../../../utils/exportToExcel";
import { CSVLink, CSVDownload } from "react-csv";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import UploadChargeback from "../../../components/ModalContent/UploadChargeback/UploadChargeback";
import { customStyles } from "../../../constants/modalStyles";
import Bulk from "../../../components/multipleUpload/Bulk";

const ChargebackButton = ({
  data,
  fileName,
  headers,
  fetchChargebacks
}: any) => {
  const handleClick = () => {
    exportToXLS(data, fileName);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [bulkModalIsOpen, setBulkModalIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function closeBulkModal() {
    setBulkModalIsOpen(false);
  }
  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.exportMenu}>
          <div className={styles.export} onClick={handleDropdown}>
            New Chargeback <DropArrow className={styles.dropArrow} />
          </div>

          {dropdown && (
            <div className={styles.exportDropdown}>
              <div
                onClick={() => {
                  handleClickAway();
                  setIsOpen(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Upload New Chargeback
              </div>
              <div
                onClick={() => {
                  handleClickAway();
                  setBulkModalIsOpen(true);
                }}
                style={{ cursor: "pointer", marginTop: "5px" }}
              >
                Bulk Chargeback
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <UploadChargeback
          fetchChargebacks={fetchChargebacks}
          closeModal={closeModal}
        />
      </Modal>

      <Modal
        isOpen={bulkModalIsOpen}
        onRequestClose={closeBulkModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Bulk fetchChargebacks={fetchChargebacks} closeModal={closeBulkModal} />
      </Modal>
    </Fragment>
  );
};

export default ChargebackButton;
