import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.scss";
import { ReactComponent as PayinvertLogo } from "../../assets/images/Logos/Pay-Invert-Logo.svg";
import { ReactComponent as BellIcon } from "../../assets/images/Icons/bell.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeNavbar } from "../../redux/actions/navbar/navbarActions";
import { useHistory, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import ProfileIcon from "../../assets/images/Icons/profile2.svg";
import SignOutIcon from "../../assets/images/Icons/signout.svg";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@mui/material/Fade";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ProfileButton from "../profilebutton/ProfileButton";
import NotificationButton from "../notificationButton/NotificationButton";
import ActionButton from "../actionButton/ActionButton";
import {
  dataAccount,
  dataDashboard,
  dataSettings,
  dataTransaction,
  dataLimits,
} from "../../utils/subMenu";
import { combinedMenu } from "../../utils/combinedMenu";
import { menu } from "../../utils/menu";
import { Link } from "react-router-dom";

const NavBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;

  const [menuLink, setMenuLink] = React.useState<null | HTMLElement>(null);

  const openMenu = ({ url }: any) => {
    history.push(url);
  };

  const openSubmenu = (url: any) => {
    history.push(url);
  };

  const subMenuCombined = {
    ...dataDashboard,
    ...dataAccount,
    ...dataTransaction,
    ...dataSettings,
  };

  const [dataRef, setDataRef] = useState<
    { id: string | number; menu: string; url: string }[]
  >([]);

  const [selectedMenu, setSelectedMenu] = useState<string | number>("");
  const [selectedNav, setSelectedNav] = useState<string | number>("");

  const [isDesktop, setDesktop] = useState(window.innerWidth > 920);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const goHome = (event: any) => {
    history.push("/dashboard");
    openMenu("/dashboard");
    setSelectedMenu(1);
    setSelectedNav(1);
    dispatch(changeNavbar("Home"));
  };

  const handleAccount = () => {
    history.push("/dashboard/settings/profile");
    setAnchorEl(null);
  };

  const signOutHandler = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  useEffect(() => {
    if (
      path === "/dashboard" ||
      path === "/dashboard/compliance" ||
      path.includes("/dashboard/compliance/compliance-details")
    ) {
      setDataRef(dataDashboard);
    } else if (path.slice(11, 19) === "accounts") {
      setDataRef(dataAccount);
    } else if (path.slice(11, 23) === "transactions") {
      setDataRef(dataTransaction);
    } else if (path.slice(11, 17) === "limits") {
      setDataRef(dataLimits);
    }
    else setDataRef(dataSettings);
  }, [path]);

  const [checked, setChecked] = useState<boolean>(false);
  const handleChecked = () => {
    setChecked(!checked);
  };
  const { navbarRoute } = useSelector((state) => state.navbarReducer);
  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  return (
    <div>
      {isDesktop ? (
        <div className={styles.container}>
          <div className={styles.navBar}>
            <div className={styles.logo} onClick={goHome}>
              <PayinvertLogo />
            </div>

            <div className={styles.menu}>
              {menu.map(({ id, menuName, url }) => (
                <div
                  key={id}
                  className={
                    selectedMenu === id ? styles.activeMenu : styles.menuItem
                  }
                  onClick={() => {
                    openMenu({ url });
                    setSelectedMenu(id);
                    setSelectedNav(1);
                    dispatch(changeNavbar(menuName));
                  }}
                >
                  {selectedMenu === id ? (
                    <div className={styles.activeColor}>
                      <div className={styles.activeColorText}>{menuName}</div>
                    </div>
                  ) : (
                    <div>{menuName}</div>
                  )}
                </div>
              ))}
            </div>

            <div className={styles.activitySection}>
              <div>
                <ActionButton />
              </div>
              <div className={styles.notification}>
                {/* <BellIcon />
                {subsidiaryInfo?.data?.notifications?.length > 0 && ( */}
                {/* <div className={styles.notificationCount}> */}
                <div>
                  <NotificationButton />
                </div>
                {/* </div>
                )} */}
              </div>
              <div>
                <ProfileButton />
              </div>
            </div>
          </div>
          <Divider light />
          <div className={styles.subMenu}>
            {dataRef &&
              dataRef.map(({ id, menu, url }) => (
                <div
                  key={id}
                  onClick={() => {
                    history.push(url);
                    setSelectedNav(id);
                    dispatch(changeNavbar(menu));
                  }}
                  className={
                    selectedNav === id
                      ? styles.activeMenuItem
                      : styles.subMenuItem
                  }
                >
                  {selectedNav === id ? (
                    <div className={styles.activeItemColor}>{menu}</div>
                  ) : (
                    <div>{menu}</div>
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className={styles.containerMobile}>
          <div className={styles.navBarMobile}>
            <div className={styles.logoMobile} onClick={goHome}>
              <PayinvertLogo />
            </div>

            <div className={styles.mobileMenu}>
              <MenuIcon
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </MenuIcon>
              <div className={styles.mobileMenuDiv}>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                //  TransitionComponent={Fade}
                >
                  {combinedMenu.map(({ id, menuName, url, submenu }) => (
                    <div onClick={handleClose}>
                      <div className={styles.accountMobile} key={id}>
                        <div
                          className={styles.mobileMenuItem}
                          onClick={() => history.push(url)}
                        >
                          {menuName}
                        </div>
                        <div className={styles.mobileSubMenu}>
                          {submenu.map(({ subId, sub, subUrl }) => (
                            <div
                              className={styles.eachMenu}
                              key={subId}
                              onClick={() => history.push(subUrl)}
                            >
                              <div className={styles.text}>{sub}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Divider light />
                    </div>
                  ))}

                  <MenuItem onClick={handleClose}>
                    <div className={styles.account} onClick={handleAccount}>
                      <div>
                        <img src={ProfileIcon} alt="Profile icon" />
                      </div>
                      <div className={styles.accountDetail}>Profile</div>
                    </div>
                  </MenuItem>

                  <MenuItem onClick={handleClose}>
                    <div onClick={signOutHandler} className={styles.account}>
                      <div>
                        <img src={SignOutIcon} alt="Sign out icon" />
                      </div>
                      <div className={styles.signOut}>Sign Out</div>
                    </div>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
