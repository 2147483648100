import React, { useState, ReactNode, Fragment, useEffect } from "react";
import styles from "./Usermenu.module.scss";
import Select from "react-select";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client, { formClient } from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import PaymentModal from "../../ModalContent/PaymentModal/PaymentModal";
import OutlineTextInput from "../../input/OutlineTextInput";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";

type MenuProps = {
  menuContent: string;
};

const isAlphabets = (str: string) => /^[a-zA-Z]*$/.test(str);
const isAlphabetsOrNumbers = (str: string) => /^[A-Za-z0-9 ]*$/.test(str);

const UserMenu = ({
  firstName,
  lastName,
  fetchUsers,
  role,
  userId,
  userStatus
}: any) => {
  const dispatch = useDispatch();
  const [referenceData, setReferenceData] = useState<any>();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const closeModalDetails = () => {
    setIsOpenDetails(false);
  };
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  interface initTypes {
    firstname: string;
    lastname: string;
    role: string;
    // userId: string
  }

  const initProps: initTypes = {
    firstname: firstName,
    lastname: lastName,
    role: role
    // userId: userId
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [errorText, setErrorText] = React.useState("");
  const [touchedName, setTouchedName] = React.useState(false);
  const [touchedDescription, setTouchDescription] = React.useState(false);
  const [touchedRole, setTouchRole] = React.useState(false);
  const [rolesData, setRolesData] = useState<any>();

  const [val, setVal] = useState("");

  const [selectedRole, setSelectedRole] = React.useState<any>({
    value: 1,
    label: role
  });

  const fetchRoles = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(`role/users`);
      setRolesData(data?.data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  let roleOptions = rolesData?.map(function (rol: any) {
    return { value: rol.id, label: rol.name };
  });

  const disableFunction = () => {
    if (
      inputs.firstname.length &&
      inputs.lastname.length &&
      inputs.role.length
    ) {
      return true;
    }
  };

  const updatePropsAlphabets = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    if (isAlphabets(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    } else {
      setErrorText("Please input alphabets only");
    }
  };

  const updatePropsAlphabetsOrNumbers = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    if (isAlphabetsOrNumbers(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    } else {
      setErrorText("Please input alphabets or numbers only");
    }
  };

  const updatePaymentLink = async () => {
    let formdata = new FormData();
    formdata.append("firstname", inputs.firstname);
    formdata.append("lastname", inputs.lastname);
    formdata.append("roleid", selectedRole?.value);
    formdata.append("avatar", "");
    try {
      const data: any = await formClient.post("user/update", formdata);
      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
      fetchUsers();
      closeModal();
    } catch (error: any) {
      closeModal();
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const updateStatus = async (status: string) => {
    try {
      const data: any = await client.patch(
        `user/update/status/${userId}/${status}`
      );
      const message = data?.data?.message;
      fetchUsers();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const handleTouchName = () => {
    setTouchDescription(false);
    setTouchRole(false);
    setTouchedName(true);
  };

  const handleTouchDescription = () => {
    setTouchedName(false);
    setTouchRole(false);
    setTouchDescription(true);
  };

  const handleTouchRole = () => {
    setTouchedName(false);
    setTouchDescription(false);
    setTouchRole(true);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      inputs.firstname.length >= 3 &&
      inputs.lastname.length >= 3 &&
      inputs.role.length >= 3
    ) {
      updatePaymentLink();
    } else {
      return dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: "Please input at least 3 alphabets"
        })
      );
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);
  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName="Edit Role"
                onclick={() => setIsOpen(true)}
              />
              {userStatus === "Active" || userStatus === "New" ? (
                <Menus
                  icon={ViewIcon}
                  optionName="Deactivate User"
                  onclick={() => {
                    updateStatus("deactivate");
                  }}
                />
              ) : (
                <Menus
                  icon={ViewIcon}
                  optionName="Activate User"
                  onclick={() => {
                    updateStatus("activate");
                  }}
                />
              )}

              {/* <Menus icon={CopyIcon} optionName="Copy Link" /> */}
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      // className={styles.modal}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Edit Role</div>
              <CloseIcon onClick={closeModal} />
            </div>
          </div>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                handleChange={updatePropsAlphabets}
                inputName="firstname"
                inputLabel="First Name"
                inputValue={inputs.firstname}
                onFocus={handleTouchName}
                InputHelper={touchedName && errorText}
                error={touchedName && errorText}
              />
              <OutlineTextInput
                handleChange={updatePropsAlphabets}
                inputName="lastname"
                inputLabel="Last Name"
                inputValue={inputs.lastname}
                onFocus={handleTouchDescription}
                InputHelper={touchedDescription && errorText}
                error={touchedDescription && errorText}
              />
              <div>
                <label className={styles.inputLabel}>Role</label>
                <Select
                  defaultValue={selectedRole}
                  onChange={setSelectedRole}
                  options={roleOptions}
                  // isMulti
                  // styles={customStyles}
                  className={styles.select}
                />
              </div>
              {/* <OutlineTextInput
                handleChange={updatePropsAlphabetsOrNumbers}
                inputName="role"
                inputLabel="Role"
                inputValue={inputs.role}
                onFocus={handleTouchRole}
                InputHelper={touchedRole && errorText}
                error={touchedRole && errorText}
              /> */}
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Save
              </div>
            ) : (
              <div className={styles.notClickable}>Save</div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenDetails}
        onRequestClose={closeModalDetails}
        contentLabel="Example Modal"
        style={customStyles}
      // className={styles.modal}
      >
        <PaymentModal
          closeModal={closeModalDetails}
          reference={referenceData?.data?.payment_link}
        />
      </Modal>
    </Fragment>
  );
};

export default UserMenu;
