import React from "react";

export interface CollectionLimitsData {
  currency: string;
  minimum_amount: string;
  maximum_amount: string;
  cummulative_daily: string;
  subsidiary_name: string;
  subsidiary_email: string;
}

export const ColumnCollectionLimits = [
  {
    Header: "Currency",
    accessor: "currency"
  },
  {
    Header: "Minimum Amount",
    accessor: "minimum_amount"
  },
  {
    Header: "Maximum Amount",
    accessor: "maximum_amount"
  },
  {
    Header: "Cummulative",
    accessor: "cummulative_daily"
  },
  {
    Header: "Subsidiary Name",
    accessor: "subsidiary_name"
  },
  {
    Header: "Subsidiary Email",
    accessor: "subsidiary_email"
  }
];

export interface PendingLimitsData {
  currency: string;
  minimum_amount: string;
  maximum_amount: string;
  cummulative_daily: string;
  subsidiary_name: string;
  subsidiary_email: string;
  is_approved?: any;
  action?: React.ReactNode;
}

export const ColumnPendingLimits = [
  {
    Header: "Currency",
    accessor: "currency"
  },
  {
    Header: "Minimum Amount",
    accessor: "minimum_amount"
  },
  {
    Header: "Maximum Amount",
    accessor: "maximum_amount"
  },
  {
    Header: "Cummulative",
    accessor: "cummulative_daily"
  },
  {
    Header: "Subsidiary Name",
    accessor: "subsidiary_name"
  },
  {
    Header: "Subsidiary Email",
    accessor: "subsidiary_email"
  },
  {
    Header: "Status",
    accessor: "is_approved"
  },
  {
    Header: "Action",
    accessor: "action"
  }
];

export interface PayoutLimitsDataCreate {
  currency: string;
  frequency: string;
  transfer_limit: string;
  cummulative_daily: string;
  subsidiary_name: string;
  subsidiary_email: string;
  is_approved?: any;
  action?: React.ReactNode;
}

export const ColumnPayoutLimitsCreate = [
  {
    Header: "Currency",
    accessor: "currency"
  },
  {
    Header: "Frequency",
    accessor: "frequency"
  },
  {
    Header: "Transfer Limit",
    accessor: "transfer_limit"
  },
  {
    Header: "Cummulative",
    accessor: "cummulative_daily"
  },
  {
    Header: "Subsidiary Name",
    accessor: "subsidiary_name"
  },
  {
    Header: "Subsidiary Email",
    accessor: "subsidiary_email"
  },
  {
    Header: "Status",
    accessor: "is_approved"
  },
  {
    Header: "Action",
    accessor: "action"
  }
];

export interface PayoutLimitsData {
  currency: string;
  frequency: string;
  transfer_limit: string;
  cummulative_daily: string;
  subsidiary_name: string;
  subsidiary_email: string;
}

export const ColumnPayoutLimits = [
  {
    Header: "Currency",
    accessor: "currency"
  },
  {
    Header: "Frequency",
    accessor: "frequency"
  },
  {
    Header: "Transfer Limit",
    accessor: "transfer_limit"
  },
  {
    Header: "Cummulative",
    accessor: "cummulative_daily"
  },
  {
    Header: "Subsidiary Name",
    accessor: "subsidiary_name"
  },
  {
    Header: "Subsidiary Email",
    accessor: "subsidiary_email"
  }
];
