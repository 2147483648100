import React, { useState, ReactNode, Fragment, useEffect } from "react";
import styles from "./Settlementmenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import PaymentModal from "../../ModalContent/PaymentModal/PaymentModal";
import ChargebackMenuModal from "../../ModalContent/SettlementMenuModal/ChargebackMenuModal";
import ChargebackOrderModal from "../../ModalContent/SettlementMenuModal/ChargebackOrderModal";

type MenuProps = {
  menuContent: string;
};

const ChargebackMenu = ({
  reference,
  status,
  fetchPaymentLink,
  id,
  menuData,
  dataType
}: any) => {
  const dispatch = useDispatch();
  const [referenceData, setReferenceData] = useState<any>();
  const [menuOption, setMenuOption] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenDetails, setIsOpenDetails] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const closeModalDetails = () => {
    setIsOpenDetails(false);
  };
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  const getLinkByReference = async () => {
    try {
      const data: any = await client.get(
        `order/all/paginated?customer_id=${id}`
      );
      const paymentLink = data?.data;
      //   console.log("settlement", paymentLink);
      data?.data?.status_code === "00" && setIsOpenDetails(true);
      setReferenceData(paymentLink);
      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };
  const handleStatus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    closeModal();
    getLinkByReference();
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName="View Details"
                onclick={() => {
                  setIsOpenDetails(true);
                }}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>

      <Modal
        isOpen={modalIsOpenDetails}
        onRequestClose={closeModalDetails}
        contentLabel="Example Modal"
        style={customStyles}
      >
        {
          dataType === "chargeback" ? <ChargebackOrderModal
            closeModal={closeModalDetails}
            menuData={menuData} /> :
            <ChargebackMenuModal
              closeModal={closeModalDetails}
              menuData={menuData}
            />
        }
      </Modal>
    </Fragment>
  );
};

export default ChargebackMenu;
