import React, { Dispatch, useEffect, MouseEventHandler } from "react";
import styles from "./Settlementmenu.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";
import moment from "moment";

type PaymentProps = {
    closeModal: MouseEventHandler<SVGSVGElement>;
    menuData: any;
};
const ChargebackOrderModal = ({ closeModal, menuData }: PaymentProps) => {
    return (
        <div className={styles.modalBody}>
            <div className={styles.modalHeader}>
                <div className={styles.background}>
                    <div className={styles.linkDetails}>Chargeback details</div>
                    <CloseIcon onClick={closeModal} />
                </div>
            </div>
            <div className={styles.modalContent}>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Date Created</div>
                    <div className={styles.content}>
                        {moment(menuData?.date_created).format("MMMM Do YYYY, h:mm a")}
                    </div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Due Created</div>
                    <div className={styles.content}>
                        {moment(menuData?.due_date).format("MMMM Do YYYY, h:mm a")}
                    </div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Merchant name</div>
                    <div className={styles.content}>{menuData?.merchant_name}</div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Customer email</div>
                    <div className={styles.content}>{menuData?.customer_email}</div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Transaction amount</div>
                    <div className={styles.content}>{menuData?.currency} {menuData?.amount}</div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>RRN</div>
                    <div className={styles.content}>{menuData?.rrn}</div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Transaction Reference</div>
                    <div className={styles.content}>{menuData?.transaction_reference}</div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Currency</div>
                    <div className={styles.content}>{menuData?.currency}</div>
                </div>
                <div className={styles.modalDetails}>
                    <div className={styles.link}>Status</div>
                    <div className={styles.content}>
                        {menuData?.status === "Successful" ? (
                            <div className={styles.completed}>Successful</div>
                        ) : menuData?.status === "New" ? (
                            <div className={styles.processing}>New</div>
                        ) : (
                            <div className={styles.declined}>Failed</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChargebackOrderModal;
