import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import ParentContainer from "../components/ParentContainer/ParentContainer";
import SignIn from "../views/SignIn/SignIn";
import ForgotPassword from "../views/SignIn/ForgotPassword";
import DashboardRoutes from "./DashboardRoutes";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoutes";
import VerifyUser from "../views/SignIn/VerifyUser";

const ScrollToTop = () => {
  const { pathname } = useLocation();


  console.log("pathname", pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default function AppRoutes() {
  return (
    <Router>
      <ScrollToTop />
      <ParentContainer>
        <Switch>
          <PublicRoute exact path="/">
            <SignIn />
          </PublicRoute>
          <PublicRoute exact path="/forgot-password/:email">
            <ForgotPassword />
          </PublicRoute>
          <PublicRoute exact path="/password/:token">
            <VerifyUser />
          </PublicRoute>
          <PrivateRoute path="/dashboard">
            <DashboardRoutes />
          </PrivateRoute>
        </Switch>
      </ParentContainer>
    </Router>
  );
}
