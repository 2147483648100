import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import styles from "./ActionButton.module.scss";
import BoltIcon from "@mui/icons-material/Bolt";
import Modal from "react-modal";
import { makeStyles } from "@material-ui/styles";
import { customStyles } from "../../constants/modalStyles";
import { useHistory } from "react-router-dom";
import SettlementcycleModal from "../ModalContent/SettlementcycleModal/SettlementcycleModal";
import SettlementgroupsModal from "../ModalContent/SettlementcycleModal/SettlementgroupsModal";
import settlementApi from "../../api/settlementCycle";
import settlementGroupsApi from "../../api/settlementGroups";
import useApi from "../../hooks/useApi";
import currencyApi from "../../api/currencies";
import subsidiaryApi from "../../api/subsidiaries";
import settlementCycleApi from "../../api/settlementCycle";
import { actionButtonMenu } from "../../utils/actionButtonMenu";
import KycIcon from "../../assets/images/Icons/kyc.svg";
import SettlementCycleIcon from "../../assets/images/Icons/settlement-cycle.svg";
import SettlementGroupIcon from "../../assets/images/Icons/settlement-groups.svg";
import TermsIcon from "../../assets/images/Icons/terms.svg";
import { StyleSharp } from "@material-ui/icons";

interface user {
  email: string;
  firstname: string;
  lastname: string;
}

export default function ActionButton() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  const [cycleModalIsOpen, setCycleIsOpen] = React.useState(false);
  const [groupModalIsOpen, setGroupIsOpen] = React.useState(false);

  function closeCycleModal() {
    setCycleIsOpen(false);
  }

  function closeGroupModal() {
    setGroupIsOpen(false);
  }

  const getSettlementCyclesApi = useApi(settlementApi.getSettlementCycles);

  const fetchSettlementCycles = async () => {
    getSettlementCyclesApi.request();
  };

  const getSettlementGroupsApi = useApi(
    settlementGroupsApi.getSettlementGroups
  );

  const fetchSettlementGroups = async () => {
    getSettlementGroupsApi.request();
  };

  useEffect(() => {
    fetchSettlementGroups();
    fetchCurrencies();
    fetchSubsidiaries();
    fetchSettlementCycles();
  }, []);

  const getCurrenciesApi = useApi(currencyApi.getCurrencies);

  const fetchCurrencies = async () => {
    getCurrenciesApi.request();
  };

  const currencies = getCurrenciesApi?.data?.data;

  const getSubsidiariesApi = useApi(subsidiaryApi.getSubsidiaries);

  const settlementPlans = getSettlementCyclesApi?.data?.settlement_plans;

  const fetchSubsidiaries = async () => {
    getSubsidiariesApi.request();
  };
  const subsidiaries = getSubsidiariesApi?.data?.items;

  const useStyles = makeStyles({
    root: {
      "&:hover": {
        background: "none"
      }
    },
    list: {
      backgroundColor: "#ffffff",
      overflow: "hidden",
      color: "rgba(0, 40, 65, 0.8)",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: "0"
    },
    primary: {
      fontSize: "212px"
    },
    paper: {
      boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.2)"
    }
  });
  const classes = useStyles();

  const handleKYC = () => {
    history.push("/dashboard/compliance");
    setAnchorEl(null);
  };

  const handleSettlementCycle = () => {
    setCycleIsOpen(true);
    setAnchorEl(null);
  };

  const handleSettlementGroup = () => {
    setGroupIsOpen(true);
    setAnchorEl(null);
  };

  const handleTerms = () => {};

  return (
    <div>
      <div
        id="fade-button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <div className={styles.buttonAction} onClick={handleClick}>
          <BoltIcon />
          Actions
        </div>
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        classes={{
          list: classes.list,
          paper: classes.paper,
          root: classes.root
        }}
      >
        <div className={styles.menuItem}>
          <div className={styles.account} onClick={handleKYC}>
            <div className={styles.image}>
              <img src={KycIcon} alt="KYC icon" />
            </div>
            <div className={styles.accountDetail}>
              KYC requests & compliance
            </div>
          </div>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.account} onClick={handleSettlementCycle}>
            <div className={styles.image}>
              <img src={SettlementCycleIcon} alt="Settlement cycle icon" />
            </div>
            <div className={styles.accountDetail}>New settlement cycle</div>
          </div>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.account} onClick={handleSettlementGroup}>
            <div className={styles.image}>
              <img src={SettlementGroupIcon} alt="Settlement group icon" />
            </div>
            <div className={styles.accountDetail}>New settlement group</div>
          </div>
        </div>
      </Menu>

      <Modal
        isOpen={cycleModalIsOpen}
        onRequestClose={closeCycleModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <SettlementcycleModal
          fetchSettlementCycles={fetchSettlementCycles}
          closeModal={closeCycleModal}
        />
      </Modal>

      <Modal
        isOpen={groupModalIsOpen}
        onRequestClose={closeGroupModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <SettlementgroupsModal
          fetchSettlementGroups={fetchSettlementGroups}
          closeModal={closeGroupModal}
          currencies={currencies}
          subsidiaries={subsidiaries}
          settlementPlans={settlementPlans}
        />
      </Modal>
    </div>
  );
}
