import React from "react";
import styles from "./Statusview.module.scss";

type StatusProps = {
  status: string;
  green?: string;
  red?: string;
  orange?: string;
  blue?: string;
  suspended?: string;
  final?: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  style?: any
};
const StatusView = ({
  status,
  green,
  orange,
  red,
  blue,
  suspended,
  final,
  value,
  style,
  setValue
}: StatusProps) => {
  return (
    <div style={style}>
      {status === red ? (
        <div className={styles.declined}>{red}</div>
      ) : status === suspended ? (
        <div className={styles.declined}>{suspended}</div>
      ) : status === final ? (
        <div className={styles.processing}>{final}</div>
      ) : status === orange ? (
        <div className={styles.processing}>{orange}</div>
      ) : status === green ? (
        <div className={styles.completed}>{green}</div>
      ) : status === blue ? (
        <div className={styles.review}>{blue}</div>
      ) : null}
    </div>
  );
};

export default StatusView;
