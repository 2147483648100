import React, { Fragment, useState, useEffect } from "react";
import styles from "./Notifications.module.scss";
import { Divider, Grid, Box } from "@mui/material";
import Switch from "react-switch";
import { notificationData } from "../../../utils/notificationData";
import notificationsApi from "../../../api/notifications";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";

const Notifications = () => {
  const dispatch = useDispatch();
  const getNotificationsApi = useApi(notificationsApi.getNotifications);

  const fetchNotifications = async () => {
    getNotificationsApi.request();
  };

  const notificationsData = getNotificationsApi?.data?.data;

  console.log("notificationData", notificationsData);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckedPush = async (id: number, push: boolean) => {
    const payload = { update_push: !push, notification_type_id: id };
    try {
      const data: any = await client.post("notification/user/update", payload);
      const message = data?.data?.message;
      fetchNotifications();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleCheckedEmail = async (id: number, email: boolean) => {
    const payload = { update_email: !email, notification_type_id: id };
    try {
      const data: any = await client.post("notification/user/update", payload);
      const message = data?.data?.message;
      fetchNotifications();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleAllEmail = async () => {
    const payload = { update_all_email: true };
    try {
      const data: any = await client.post("notification/user/update", payload);
      const message = data?.data?.message;
      fetchNotifications();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleAllPush = async () => {
    const payload = { update_all_push: true };
    try {
      const data: any = await client.post("notification/user/update", payload);
      const message = data?.data?.message;
      fetchNotifications();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const [state, setState] = useState({});

  const handleSwitchChange = (e: any, pname: any) => {
    console.log(e.target.checked);
    setState({ ...state, pname: { [e.target.name]: e.target.checked } });
  };

  return (
    <div className={styles.notificationBody}>
      <div className={styles.header}>
        <span>Notifications</span>
      </div>

      <Grid container spacing={0}>
        <Grid item md={7} xs={6} lg={7}></Grid>
        <Grid item md={5} xs={6} lg={5}>
          <div className={styles.controlButton}>
            <div className={styles.action} onClick={handleAllPush}>
              Turn all push on
            </div>
            <div className={styles.action} onClick={handleAllEmail}>
              Turn all email on
            </div>
          </div>
        </Grid>
      </Grid>
      {notificationsData?.map((data: any, index: any) => (
        <Grid container spacing={0} key={data.id}>
          <Grid item md={7} xs={8} lg={7}>
            <div className={styles.header}>{data?.name}</div>
            <div className={styles.description}>{data?.description}</div>
          </Grid>
          <Grid item md={5} xs={3} lg={5}>
            <div className={styles.switchDiv}>
              <div className={styles.controlButton}>
                <Switch
                  checked={data?.push}
                  onChange={() => {
                    handleCheckedPush(data?.id, data?.push);
                  }}
                  offColor="#757575"
                  height={24}
                  width={48}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#19943C"
                />
                <span className={styles.ml1}>Push</span>
              </div>
              <div className={styles.controlButton}>
                <Switch
                  checked={data?.email}
                  onChange={() => {
                    handleCheckedEmail(data?.id, data?.email);
                  }}
                  offColor="#757575"
                  height={24}
                  width={48}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#19943C"
                />
                <span className={styles.ml1}>Email</span>
              </div>
            </div>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default Notifications;
