import React from "react";
import styles from "./ComplianceDetails.module.scss";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";

const ComplianceDetailsBank = ({ bankDetails }: any) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormLabel-root": {
        zIndex: "0"
      }
    }
  }));

  const classes = useStyles();

  const subBankDetails = {
    account_number: bankDetails[0]?.account_number,
    bank_name: bankDetails[0]?.bank_name,
    account_name: bankDetails[0]?.account_name
  };

  return (
    <div className={styles.roles}>
      <Box>
        <div>
          <div>
            <TextField
              className={classes.root}
              label="Account number"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {!!subBankDetails?.account_number
                      ? subBankDetails?.account_number
                      : ""}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
            <TextField
              className={classes.root}
              label="Bank name"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {!!subBankDetails?.bank_name
                      ? subBankDetails?.bank_name
                      : ""}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
            <TextField
              className={classes.root}
              label="Account name"
              id="outlined-start-adornment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {!!subBankDetails?.account_name
                      ? subBankDetails?.account_name
                      : ""}
                  </InputAdornment>
                )
              }}
              disabled={true}
            />
          </div>
        </div>
      </Box>
    </div>
  );
};

export default ComplianceDetailsBank;
