import React, { useEffect } from "react";
import styles from "./Uploadchargeback.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import SelectDropdown from "../../input/SelectDropdown";
import Select from "react-select";
import useApi from "../../../hooks/useApi";
import currencyApi from "../../../api/currencies";

const isAlphabets = (str: string) => /^[a-zA-Z ]*$/.test(str);
const UploadChargeback = ({
  closeModal,

  fetchChargebacks
}: any) => {
  const dispatch = useDispatch();

  interface initTypes {
    reference: string;
    amount: number | undefined;
    reason: string;
    frequency: number | undefined;
  }

  const initProps: initTypes = {
    reference: "",
    amount: undefined,
    reason: "",
    frequency: undefined
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [selectedCurrency, setSelectedCurrency] = React.useState<any>();
  const [startDate, setStartDate] = React.useState(new Date());

  const getCurrenciesApi = useApi(currencyApi.getCurrencies);

  const fetchCurrencies = async () => {
    getCurrenciesApi.request();
  };

  const currencies = getCurrenciesApi?.data?.data;
  let currencyOptions = currencies?.map(function (currency: any) {
    return {
      value: currency.id,
      label: currency.name,
      shortName: currency.short_name
    };
  });

  const disableFunction = () => {
    if (
      !inputs.reference ||
      !inputs.amount ||
      !inputs.reason ||
      !inputs.frequency
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const createPaymentLink = async () => {
    try {
      const data: any = await client.post<any>("chargeback/upload", [
        {
          ...inputs,
          currency: selectedCurrency?.[0]?.shortName,
          due_date: startDate.toISOString(),
          type: "Local"
        }
      ]);
      fetchChargebacks();
      closeModal();
      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New Chargeback </div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="reference"
            inputLabel="Reference"
            inputValue={inputs.reference}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="amount"
            inputLabel="Amount"
            inputValue={inputs.amount}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="reason"
            inputLabel="Reason"
            inputValue={inputs.reason}
            multiline
          />

          <div>
            <div className={styles.inputLabel}>Due Date</div>
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              minDate={moment().toDate()}
              placeholderText="Select due date"
              wrapperClassName="datePicker"
              dateFormat="dd/MM/yyyy"
            />
          </div>

          <OutlineTextInput
            handleChange={updateProps}
            inputName="frequency"
            inputLabel="Frequency"
            inputValue={inputs.frequency}
          />

          <div>
            <label className={styles.inputLabel}>Currency</label>
            <Select
              defaultValue={selectedCurrency}
              onChange={setSelectedCurrency}
              options={currencyOptions}
              isMulti
              // styles={customStyles}
              className={styles.select}
            />
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        <div className={styles.deactivate} onClick={handleSubmit}>
          Upload
        </div>
      </div>
    </div>
  );
};

export default UploadChargeback;
