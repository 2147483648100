import React, { useState, useEffect } from "react";
import styles from "./Transactiondetails.module.scss";
import client from "../../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import ChargebackIcon from "../../../assets/images/Icons/chargeback-white.svg";

const TransactionDetails = () => {
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`order/all/paginated?id=${id}`)
      .then((res: any) => {
        setCustomerDetails(res?.data?.items[0]);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  const payload = {
    order_id: id,
    amount: customerDetails?.amount,
  };

  const handleChargebacks = (e: React.SyntheticEvent) => {
    e.preventDefault();
    client
      .post("/refund/transaction", payload)
      .then((data: any) => {
        const message = data?.data?.message;
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: message,
          })
        );
      })
      .catch((error: any) => {
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  return (
    <div>
      {isLoading ? (
        <div />
      ) : (
        <>
          <div className={styles.transactionInfo}>
            <div className={styles.transactionsHeader}>
              <div
                style={{
                  color: "#616161",
                  display: "inline",
                  cursor: "pointer",
                }}
                onClick={() => history.goBack()}
              >
                Transactions
              </div>
              {" >"} Transaction details
            </div>
            <div className={styles.chargeBack} onClick={handleChargebacks}>
              <div>
                <img src={ChargebackIcon} alt="chargeback" />
              </div>
              <div className={styles.ml}>Initiate Chargeback</div>
            </div>
          </div>
          <div className={styles.topTable}>
            <div className={styles.detailsHeader}>Transaction details</div>
            <div className={styles.tableContent}>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Transaction date</div>
                <div className={styles.detailsKey}>
                  {moment(customerDetails?.date_created).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer name</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.customer_name}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer email</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.customer_email}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Transaction amount</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.currency}
                  {customerDetails?.amount_formatted}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Payment channel</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.payment_type}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Status</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.order_status}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Merchant name</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.subsidiary_name}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Reference #</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.order_reference}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Note</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.remarks}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionDetails;
