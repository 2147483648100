import React from "react";
import styles from "./Signin.module.scss";
import Logo from "../../assets/images/Logos/Pay-Invert-Logo.svg";
import { ReactComponent as SignInIcon } from "../../assets/images/Icons/signin.svg";
import { ReactComponent as AccountIcon } from "../../assets/images/Icons/account.svg";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { saveSubsidiaryInfo } from "../../redux/actions/subsidiaryInfo/subsidiaryInfoActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../constants/toastStyles";
import Modal from "react-modal";
import ResetPasswordModal from "./resetModal/ResetPasswordModal";
import { customStyles } from "../../constants/modalStyles";

const isEmail = (str: string) =>
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    str
  );
const SignIn = () => {
  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  interface initTypes {
    Email: string;
    Password?: string;
  }

  const initProps: initTypes = {
    Email: "",
    Password: ""
  };
  const [inputs, setInputs] = React.useState(initProps);
  const [errorText, setErrorText] = React.useState("");
  const [touchedEmail, setEmail] = React.useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const updateEmailProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (isEmail(newValue)) {
      setErrorText("");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    } else {
      setErrorText("Please enter a valid email address");
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    }
  };

  const handleEmail = () => {
    setEmail(true);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("login", inputs)
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(saveSubsidiaryInfo(data));
        const { status, message, token } = data.data;
        const { access_token } = token;
        sessionStorage.setItem("access_token", access_token);

        if (status === "success") {
          history.push("/dashboard");
        }
      })
      .catch((error: any) => {
        // <ErrorToast error={error} />;
        if (error.response) {
          dispatch(closeLoader());
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles
            })
          );
        } else if (error.request) {
          dispatch(closeLoader());
        } else {
          dispatch(closeLoader());
        }
      });
  };

  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} width={207} height={56} alt="pay-invert" />
      </div>
      <div className={styles.signinForm}>
        <div className={styles.signIn}>Sign in</div>
        <div className={styles.businessForm}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <OutlineTextInput
              handleChange={updateEmailProps}
              inputName="Email"
              inputLabel="Email Address"
              inputValue={inputs.Email}
              onFocus={handleEmail}
              InputHelper={touchedEmail && errorText}
              error={touchedEmail && errorText}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="Password"
              inputLabel="Password"
              inputType="password"
              inputValue={inputs.Password}
            />

            <div
              onClick={
                !inputs.Email || !inputs.Password ? () => {} : handleSubmit
              }
              className={
                !inputs.Email || !inputs.Password
                  ? styles.inactive
                  : styles.signInButton
              }
            >
              <SignInIcon />
              <span>Sign in</span>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.forgotPassword} onClick={() => setIsOpen(true)}>
        Forgot your password?
      </div>

      <div className={styles.sidebarFooter}>
        <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
        <div>All rights reserved</div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ResetPasswordModal closeModal={closeModal} />
      </Modal>
    </div>
  );
};

export default SignIn;
